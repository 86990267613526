import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import catTypingGif from './images/cat_typing.gif'; // Import the image

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: '', 
      errorInfo: '', 
      showDetails: false // State to toggle error details
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState({ 
      hasError: true, 
      error: error.toString(), 
      errorInfo: errorInfo.componentStack 
    });
  }

  navigate = () => {
    this.setState({ hasError: false, error: '', errorInfo: '', showDetails: false }); // Reset error state
    this.props.navigate(-1); // Navigate back one step
  };

  toggleDetails = () => {
    this.setState(prevState => ({ showDetails: !prevState.showDetails })); // Toggle error details
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="container text-center py-5">
          <div className="alert alert-danger" role="alert">
            
            <img src={catTypingGif} alt='Cat typing gif' />
            <br/>
            <br/>
            <br/>
            <h1>Something went wrong!</h1>
            <p>
              But not as bad as the time your goods take to be customs cleared. 
              If this persists, please contact <a href="mailto:founders@camtomx.com">founders@camtomx.com</a>
            </p>
            <button onClick={this.navigate} className="btn btn-primary mt-3">← Go Back</button>
            <br/>
            <br/>
            <button onClick={this.toggleDetails} className="btn btn-link">
              {this.state.showDetails ? "Hide Error Info" : "Show Error Info"}
            </button>
            {this.state.showDetails && (
              <div className="mt-3">
                <p className="small"><strong>Error:</strong> {this.state.error}</p>
                <p className="small"><strong>Error Info:</strong> {this.state.errorInfo}</p>
              </div>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

// Functional wrapper to provide navigate prop
const ErrorBoundaryWrapper = (props) => {
  const navigate = useNavigate();
  return <ErrorBoundary {...props} navigate={navigate} />;
};

export default ErrorBoundaryWrapper;
