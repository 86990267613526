import React, { useEffect, useState } from "react";
import mx from "../../../imagesCommon/bandera-mexico.svg";
import co from "../../../imagesCommon/colombia.png";

import logoCamtom from "../../../imagesCommon/logo_w.svg";
import logoRuffo from "../../../imagesCommon/logoRuffo_white.png";
import logoSingle from "../../../imagesCommon/logo-single.svg";

import "../../style/Sidebar.css";

import { useTranslation } from "react-i18next";
import SidebarModuleColombia from "../sidebarCommon/Co/Users/SidebarModuleColombia.js";
import SidebarModuleMexico from "../sidebarCommon/Mx/Users/SidebarModuleMexico.js";

import { Link } from "react-router-dom";

const Sidebar = ({ country }) => {
  const { t } = useTranslation();
  const [openClose, setOpenClose] = useState(false);
  const [countryCalasiffication, setCountryCalasiffication] = useState("COL");

  const logoSrc = window.location.href.includes("ruffo")
    ? logoRuffo
    : logoCamtom;

  useEffect(() => {
    console.log(countryCalasiffication);
    country(countryCalasiffication);
  }, [countryCalasiffication, country]);

  return (
    <div className={`side ${openClose ? "sidebar-open" : "sidebar-close"}`}>
      {openClose ? (
        <Link className="logoContainer-user" to={"/user-dashboard"}>
          <img className="logoSidebar-user" src={logoSrc} alt="Logo" />
        </Link>
      ) : (
        <Link className="logo" to={"/user-dashboard"}>
          <img
            className="logo-sm text-center"
            src={logoSingle}
            alt="Company Logo"
          />
        </Link>
      )}
      <small className={`my-3 text-wrap ${openClose ? "d-block" : "d-none"}`}>
        Obtén clasificación según la normativa en
      </small>
      {/* Caja de btns para cambiar pais */}
      <span
        className={`mt-3 lang d-flex ${
          openClose ? "flex-row justify-content-evenly" : "flex-column gap-2"
        }`}
      >
        <div style={{ width: "3rem" }}>
          <img
            src={mx}
            alt="mx"
            className={`w-100 cursor-pointer ${
              countryCalasiffication === "MX" ? "scale-1" : "shadow"
            }`}
            onClick={() => setCountryCalasiffication("MX")}
          />
        </div>

        <div style={{ width: "3rem" }}>
          <img
            src={co}
            alt="co"
            className={`w-100 cursor-pointer ${
              countryCalasiffication === "COL" ? "scale-1" : "shadow"
            }`}
            onClick={() => setCountryCalasiffication("COL")}
          />
        </div>
      </span>
      {/* Caja de btns para cambiar pais */}

      {countryCalasiffication === "COL" ? (
        <SidebarModuleColombia openClose={openClose} />
      ) : countryCalasiffication === "MX" ? (
        <SidebarModuleMexico openClose={openClose} />
      ) : (
        <SidebarModuleMexico openClose={openClose} />
      )}
      <div
        class="w-3rem align-self-center d-flex align-items-center justify-content-center border mt-4 rounded-circle cursor-pointer move"
        onClick={() => setOpenClose(!openClose)}
      >
        {openClose ? (
          <i className="bi bi-arrows-collapse-vertical w-100 text-center fs-3 align-self-center"></i>
        ) : (
          <i className="bi bi-arrows-expand-vertical w-100 text-center fs-3 align-self-center"></i>
        )}
      </div>
      {/* <Language openClose={openClose} /> */}
      {/* <nav className={`menu ${openClose ? "align-start" : "align-center"}`}>
        <Link
          to="/user-dashboard"
          className="menu-item"
        >
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/user-dashboard"
            )} ${
              openClose
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-house-door fs-4"></i>
            <p className={`m-0 ${openClose ? "d-block" : "d-none"}`}>
              {t("sidebar_home")}
            </p>
          </div>
          {/* HOME
        </Link>

        <Link
          to="/user-dashboard/tariff-pro-clasifications-database"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/user-dashboard/tariff-pro-clasifications-database"
            )}  ${
              openClose
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-box-seam fs-4"></i>
            <p className={`m-0 ${openClose ? "d-block" : "d-none"}`}>
              {t("sidebar_my_products")}
            </p>
          </div>
        </Link>

        <Link
          to="/user-dashboard/history"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/user-dashboard/history"
            )} ${
              openClose
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-collection fs-4"></i>
            <p className={`m-0 text break ${openClose ? "d-block" : "d-none"}`}>
              Historial
            </p>
          </div>
        </Link>

        <Link
          to="/user-dashboard/tariff-pro"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/user-dashboard/tariff-pro"
            )} ${
              openClose
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-search fs-4"></i>
            <p className={`m-0 text break ${openClose ? "d-block" : "d-none"}`}>
              {t("sidebar_clasifier")}
            </p>
          </div>
        </Link>

        {/* DIGITER 

        <Link to="/user-dashboard/digiter" className="menu-item">
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/user-dashboard/digiter"
            )} ${
              openClose
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-file-earmark-text fs-4"></i>

            <p className={`m-0 ${openClose ? "d-block" : "d-none"}`}>Digiter</p>
          </div>
        </Link>

        <div className="divider"></div>

        <Link
          to="/user-dashboard/billing"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/user-dashboard/billing"
            )} ${
              openClose
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-cash fs-4"></i>
            <p
              className={`m-0 letter-spacing text break ${
                openClose ? "d-block" : "d-none"
              }`}
            >
              Billing
            </p>
          </div>
        </Link>

        <a href="https://camtomx.com" className="menu-item" target="blank">
          <div
            className={`sidebar-list d-flex flex-row ${
              openClose
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-headset fs-4"></i>
            <p className={`m-0 ${openClose ? "d-block" : "d-none"}`}>
              {t("sidebar_service")}
            </p>
          </div>
        </a>
        <Link
          to="/user-dashboard/new-simulations"
          className="menu-item btn-new-simulations"
          // onClick={handleIsToken}
        >
          <div className={`d-flex align-items-center justify-content-center`}>
            <i className="bi bi-plus fs-4"></i>
            <p className={`m-0 ${openClose ? "d-block" : "d-none"}`}>
              {t("sidebar_new_simulations")}
            </p>
          </div>
        </Link>
        <div
          className={`sidebar-logout ${
            openClose ? "justify-content-start" : "justify-content-center m-0"
          }`}
        >
          <div
            className={`d-flex flex-row align-items-center justify-content-center ${
              openClose
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
            onClick={handleLogout}
          >
            <i className="bi bi-box-arrow-right fs-4"></i>
            <p className={`m-0 transition ${openClose ? "d-block" : "d-none"}`}>
              {t("sidebar_logout")}
            </p>
          </div>
        </div>
      </nav>
      <div
        class="w-3rem align-self-center d-flex align-items-center justify-content-center border mt-4 rounded-circle cursor-pointer move"
        onClick={() => setOpenClose(!openClose)}
      >
        {openClose ? (
          <i className="bi bi-arrows-collapse-vertical w-100 text-center fs-3 align-self-center"></i>
        ) : (
          <i className="bi bi-arrows-expand-vertical w-100 text-center fs-3 align-self-center"></i>
        )}
      </div> */}
    </div>
  );
};

export default Sidebar;
