import { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import toast from "react-hot-toast";

function TariffImg({
  onSuccess,
  token,
  setLoading,
  countryClasiffication,
  ID,
}) {
  const BASE_URL = process.env.REACT_APP_serverURLtariff;

  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [prompt, setPrompt] = useState("");

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    uploadImage(e.target.files[0]);
  };

  const uploadImage = async (file) => {
    // setLoading(true);
    try {
      const fileRef = ref(storage, `tariffPro/files/${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      setImageUrl(url);
      // console.log("File uploaded successfully:", url);
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      // setLoading(false);
    }
  };

  const handleFraction = async () => {
    // Toast para avisar que ha va a clasificar en X pais
    const toastCountry = toast(
      `Vas a clasificar bajo las normas ${
        countryClasiffication === "COL" ? "colombianas" : "mexicanas"
      }`,
      {
        // icon: "😉 ",
        style: {
          borderRadius: "10px",
          background: `${
            countryClasiffication === "COL"
              ? "linear-gradient(to bottom, #fbe964 33.33%, #fbe964 33.33%, #648bfb 66.66%, #648bfb 66.66%, #fb6464 100%)"
              : "linear-gradient(to left, #e95252 0%, #ffffff 40%, #ffffff 50%, #70e952 100%)"
          }`,
          textShadow: "2px 2px 4px rgba(0.6, 0.6, 0.6, 0.6)",
          color: "#fff",
          textAlign: "center",
        },
      }
    );
    const url = `${BASE_URL}/api/v2/text-image`;
    setLoading(true);

    const toastFracction = toast("Procesando...", {
      icon: "⏳ ",
      style: {
        borderRadius: "10px",
        background: "#fff",
        color: "#000",
      },
      duration: Infinity,
    });
    // Llenando el body
    let body = {};
    body.countryCode = countryClasiffication; // la prop de pais que viene del sidebar (usar COL O MX)
    body.tradingCompanyEmployeeId = ID; // Vincular la clasif con el usuario

    if (image) {
      body.imgUrl = imageUrl;
    }

    if (prompt) {
      body.userDescription = prompt;
      console.log(prompt);
    }

    if (!image && !prompt) {
      setLoading(false);
      toast.error("Campos vacíos", {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      return;
    }
    // console.log(body, "este es el body");
    const options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(url, options);
      const res = await response.json();
      console.log(res, "img");
      toast.dismiss(toast);

      if (response.ok || response.status === 422) {
        // A veces viene un mensaje de error en una respuesta.ok
        toast.success(res.message || res.error || "Proceso completado", {
          id: toastFracction,
          duration: 5000,
        });

        onSuccess(res);
        setLoading(false);
      } else {
        toast.error(res.message || res.error, {
          icon: "❗❗ ",
          id: toastFracction,
          duration: 5000,
        });
        setLoading(false);
      }
      //
    } catch (error) {
      console.log(error);

      setLoading(false);
      toast.dismiss(toast.error);

      toast.error(error, {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const handleExitBtn = () => {
    setImage(null);
  };

  const handleToast = () => {
    // Toast para avisar que ha va a clasificar en X pais
    const toastCountry = toast(
      `Vas a clasificar bajo las normas ${
        countryClasiffication === "COL" ? "colombianas" : "mexicanas"
      }`,
      {
        // icon: "😉 ",
        style: {
          borderRadius: "10px",
          background: `${
            countryClasiffication === "COL"
              ? "linear-gradient(to bottom, #fbe964 33.33%, #fbe964 33.33%, #648bfb 66.66%, #648bfb 66.66%, #fb6464 100%)"
              : "linear-gradient(to left, #e95252 0%, #ffffff 40%, #ffffff 50%, #70e952 100%)"
          }`,
          textShadow: "2px 2px 4px rgba(0.6, 0.6, 0.6, 0.6)",
          color: "#fff",
          textAlign: "center",
        },
      }
    );
  };
  const handleToastAndFraction = () => {
    handleToast();

    setTimeout(() => {
      handleFraction();
    }, 2000);
  };

  return (
    <div className="d-flex flex-column gap-3">
      {/* DEPLOY AREA */}
      <div className="w-100 d-flex flex-column align-items-center">
        <h5 className="align-self-start my-5">
          1. Sube una imagen del producto
        </h5>
        <input
          type="file"
          id="fileUpload"
          accept="image/png, image/jpeg"
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
        <label
          htmlFor="fileUpload"
          className="w-75 d-flex flex-column align-items-center p-4 dashed-border-dispatchTariff"
        >
          <h3 className="dispatch-title-tariff text-center">
            Sube una imagen de tu mercancía
          </h3>
          <p>
            La clasificaremos en menos de 15 segundos
            <i className="bi bi-stars"></i>
          </p>
          <small className="dispatch-small">
            No te preocupes, podrás agregar texto antes de clasificarla.
          </small>
          <span className="d-flex flex-column align-items-center">
            <i className="bi bi-cloud-upload font-lg text-icon-tariff"></i>
            <small className="dispatch-small">Formato: JPG, PNG</small>
          </span>
        </label>

        {/* Uploaded ? */}
        <div className={image ? "product-uploaded-dispatch" : ""}>
          <div>
            {image && (
              <i className="bi bi-check-circle-fill me-2 text-success"></i>
            )}
            {image && image?.name}
          </div>
          {image && (
            <span onClick={handleExitBtn}>
              <i className="bi bi-x-circle-fill text-danger"></i>
            </span>
          )}
        </div>

        <h5 className="align-self-start my-5">
          2. Escribe una descripción del producto (Opcional)
        </h5>
        <div className="description-instructions w-100">
          <textarea
            className="w-100 p-3"
            placeholder="Descipción"
            value={prompt}
            onChange={handlePromptChange}
            // disabled={loading}
          ></textarea>
        </div>
      </div>

      <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-4">
        <button
          className="btn-camtom"
          onClick={handleToastAndFraction}
          disabled={!imageUrl}
        >
          Obtener clasificación
        </button>
      </div>
      {/* Uploaded ? */}
    </div>
  );
}

// upload-area-dispatch-tariff eliminar clase
// dashed-border-dispatch-tariff

export default TariffImg;
