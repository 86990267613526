import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

//Componentes
import TariffNoms from "./TariffNoms.js";
import TariffNearly from "./TariffNearly.js";
import TariffMinimasAndAcuerdos from "./TariffMinimasAndAcuerdos.js";

const TariffResponse = ({ response, countryOfClassification }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [savedResponse, setSavedResponse] = useState(response || []);
  const [showNearlyHeading, setShowNearlyHeading] = useState(null);
  const [showNearlySubheading, setShowNearlySubheading] = useState(null);
  const [showNearlyFraction, setShowNearlyFraction] = useState(null);
  const [partida, setPartida] = useState(null);
  const [subPartida, setSubPartida] = useState(null);
  const [fraction, setFraction] = useState(null);
  const [feedback, setFeedback] = useState("");
  const navigate = useNavigate();

  // Load response from local storage on mount
  useEffect(() => {
    const savedResponse = localStorage.getItem("tariffResponse");
    if (savedResponse) {
      setSavedResponse(JSON.parse(savedResponse));
      // console.log(savedResponse, "desde effect");
    }
  }, []);

  // Save response to local storage whenever it changes
  useEffect(() => {
    if (savedResponse.length > 0) {
      localStorage.setItem("tariffResponse", JSON.stringify(savedResponse));
      // console.log(savedResponse, "desde effect");
    }
  }, [savedResponse]);

  // Update savedResponse when response prop changes
  useEffect(() => {
    if (response != null) {
      setSavedResponse(response);
    }
    if (response?.is_ambiguous) {
      setFeedback(response);
      console.warn("locoo");
    }
  }, [response]);

  const handleCopyCode = (code) => {
    try {
      navigator.clipboard.writeText(code);
      toast.success("¡Texto copiado en el portapapeles!", {
        icon: "",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } catch (err) {
      toast.error("Error al copiar el código", {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const toggleShowNearlyHeading = (index, partida) => {
    setShowNearlyHeading(showNearlyHeading === index ? null : index);
    setPartida(partida);
  };

  const toggleShowNearlySubheading = (index, subheading) => {
    setShowNearlySubheading(showNearlySubheading === index ? null : index);
    setSubPartida(subheading);
  };

  const toggleShowNearlyFraction = (index, fraction) => {
    setShowNearlyFraction(showNearlyFraction === index ? null : index);
    setFraction(fraction);
  };

  const handleRedirectToQuoter = (fraction) => {
    // Determine the base path based on the current path
    const isAgent = location.pathname.includes("agent-dashboard");
    const isUser = location.pathname.includes("user-dashboard");

    if (isAgent) {
      navigate(`/agent-dashboard/new-simulations`, {
        state: { key: fraction },
      });
    } else if (isUser) {
      navigate(`/user-dashboard/new-simulations`, {
        state: { key: fraction },
      });
    } else {
      // Handle other cases if necessary
      console.warn("Unknown dashboard type.");
    }
  };

  return (
    <div>
      {Array.isArray(savedResponse) && savedResponse.length > 0 ? (
        <ul>
          {savedResponse?.map((item, index) => (
            <li key={index} className="border fs-5 text">
              {/* Header classification response */}
              <div className="d-flex flex-column justify-content-between gap-2 w-100">
                {/* fraction_and_nico and name */}
                <div className="w-100 d-flex flex-row justify-content-between w-50">
                  <span className="d-flex flex-row gap-2">
                    <strong>{t("result_ranking")}:</strong>
                    <span>
                      {item?.fraction_and_nico?.code || "No data available"}
                    </span>
                    {item?.fraction_and_nico?.code && (
                      <i
                        className="bi bi-copy ms-3 cursor-pointer"
                        onClick={() =>
                          handleCopyCode(item.fraction_and_nico.code)
                        }
                      ></i>
                    )}
                  </span>
                  {/* Header confidence */}
                  <div className="d-flex flex-row justify-content-end gap-2 w-50">
                    <span
                      className={`reliability w-50 text-center ${getConfidenceClass(
                        item?.overall_confidence
                      )}`}
                    >
                      {getConfidenceText(item?.overall_confidence)}
                    </span>
                  </div>
                </div>
                <span className="w-100 d-flex flex-row gap-2">
                  <strong className="w-20">{t("result_name")}:</strong>
                  <span className="w-90">{item?.fraction_and_nico?.name}</span>
                </span>
              </div>

              {/* ------------------------------------------------------------------------------- */}

              <section className="d-flex flex-row w-100 pt-3 font-gray-strong strong">
                <span className="w-50">Sección</span>
                <span className="w-75">Descripción</span>
              </section>
              <hr className="my-1" />

              {/* Classification layers */}
              <section className="d-flex flex-column column-gap">
                {/* ---------------------------------- */}
                {/* Chapter */}
                <div className="d-flex flex-row column-gap align-items-center">
                  <span className="d-flex flex-row column-gap w-50">
                    <div className="d-flex flex-column w-50">
                      <p className="fs-5 text">{item?.chapter.code}</p>
                      <p className="fw-semibold text-gray fs-5 text">
                        Capítulo
                      </p>
                    </div>
                  </span>
                  <span className="w-75 fs-6">{item?.chapter.name}</span>
                </div>
                <hr className="my-1" />

                {/* ---------------------------------- */}
                {/* Heading */}
                <div className="d-flex flex-row column-gap align-items-center">
                  <span className="d-flex flex-row column-gap w-50">
                    <div className="d-flex flex-column w-50">
                      <p className="fs-5 text">{item?.heading.code}</p>
                      <p className="fw-semibold text-gray fs-5 text">Partida</p>
                    </div>
                    <div class="d-flex align-items-center justify-content-center w-50">
                      {showNearlyHeading === index && partida === "heading" ? (
                        <i
                          className="bi bi-chevron-contract"
                          onClick={() =>
                            toggleShowNearlyHeading(index, "heading")
                          }
                        ></i>
                      ) : (
                        <i
                          className="bi bi-chevron-expand"
                          onClick={() =>
                            toggleShowNearlyHeading(index, "heading")
                          }
                        ></i>
                      )}
                    </div>
                  </span>
                  <span className="w-75 fs-6">{item?.heading.name}</span>
                </div>
                <div
                  className={`${
                    showNearlyHeading === index && partida === "heading"
                      ? "d-block"
                      : "d-none"
                  } w-100`}
                >
                  <TariffNearly
                    tariff={item?.fraction.code}
                    nearly="partida"
                    nearlyRef={item?.heading.code}
                    countryOfClassification={countryOfClassification}
                  />
                </div>
                <hr className="my-1" />

                {/* ---------------------------------- */}
                {/* Subheading */}
                <div className="d-flex flex-row column-gap align-items-center">
                  <span className="d-flex flex-row column-gap w-50">
                    <div className="d-flex flex-column w-50">
                      <p className="fs-5 text">{item?.subheading.code}</p>
                      <p className="fw-semibold text-gray fs-5 text">
                        Subpartida
                      </p>
                    </div>
                    <div class="d-flex align-items-center justify-content-center w-50">
                      {showNearlySubheading === index &&
                      subPartida === "subheading" ? (
                        <i
                          className="bi bi-chevron-contract"
                          onClick={() =>
                            toggleShowNearlySubheading(index, "subheading")
                          }
                        ></i>
                      ) : (
                        <i
                          className="bi bi-chevron-expand"
                          onClick={() =>
                            toggleShowNearlySubheading(index, "subheading")
                          }
                        ></i>
                      )}
                    </div>
                  </span>
                  <span className="w-75 fs-6">{item?.subheading.name}</span>
                </div>
                <div
                  className={`${
                    showNearlySubheading === index &&
                    subPartida === "subheading"
                      ? "d-block"
                      : "d-none"
                  } w-100`}
                >
                  <TariffNearly
                    tariff={item?.fraction.code}
                    nearly="subpartida"
                    nearlyRef={item?.subheading.code}
                    countryOfClassification={countryOfClassification}
                  />
                </div>
                <hr className="my-1" />

                {/* ---------------------------------- */}
                {/* Fraction */}
                <div className="d-flex flex-row column-gap align-items-center">
                  <span className="d-flex flex-row column-gap w-50">
                    <div className="d-flex flex-column w-50">
                      <p className="fs-5 text">{item?.fraction.code}</p>
                      <p className="fw-semibold text-gray fs-5 text">
                        {countryOfClassification === "COL"
                          ? "Nandina"
                          : "Fracción"}
                      </p>
                    </div>
                    <div class="d-flex align-items-center justify-content-center w-50">
                      {showNearlyFraction === index &&
                      fraction === "fraction" ? (
                        <i
                          className="bi bi-chevron-contract"
                          onClick={() =>
                            toggleShowNearlyFraction(index, "fraction")
                          }
                        ></i>
                      ) : (
                        <i
                          className="bi bi-chevron-expand"
                          onClick={() =>
                            toggleShowNearlyFraction(index, "fraction")
                          }
                        ></i>
                      )}
                    </div>
                  </span>
                  <span className="w-75 fs-6">{item?.fraction.name}</span>
                </div>
                <div
                  className={`${
                    showNearlyFraction === index && fraction === "fraction"
                      ? "d-block"
                      : "d-none"
                  } w-100`}
                >
                  <TariffNearly
                    tariff={item?.fraction.code}
                    nearly="fracciones"
                    nearlyRef={item?.fraction.code}
                    countryOfClassification={countryOfClassification}
                  />
                </div>
                <hr className="my-1" />

                {/* ---------------------------------- */}
                {/* Fraction and nico */}
                <div className="d-flex flex-row column-gap align-items-center">
                  <span className="d-flex flex-row column-gap w-50">
                    <div className="d-flex flex-column w-50">
                      <p className="fs-5 text">
                        {item?.fraction_and_nico?.code}
                      </p>
                      <p className="fw-semibold text-gray fs-5 text">
                        {countryOfClassification === "COL"
                          ? "Fracción Arancelaria"
                          : "NICO"}
                      </p>
                    </div>
                  </span>
                  <span className="w-75 fs-6">
                    {item?.fraction_and_nico?.name}
                  </span>
                </div>
                <hr className="my-1" />

                {countryOfClassification === "MX" ? (
                  <TariffNoms tariff={item.fraction.code} />
                ) : countryOfClassification === "COL" ? (
                  <TariffMinimasAndAcuerdos
                    tariff={item?.fraction_and_nico?.code}
                  />
                ) : null}
                <section className="d-flex flex-row justify-content-between align-items-end w-100">
                  <div className="d-flex flex-row">
                    <span className="m-2 cursor-pointer">
                      <i className="bi bi-hand-thumbs-up"></i>
                    </span>
                    <span className="m-2 cursor-pointer">
                      <i className="bi bi-hand-thumbs-down"></i>
                    </span>
                  </div>
                  {countryOfClassification === "MX" ? (
                    <button
                      className="btn-camtom-rounded"
                      onClick={() => handleRedirectToQuoter(item.fraction.code)}
                    >
                      {t("quote_customs_clearance")}
                    </button>
                  ) : null}
                </section>
              </section>
            </li>
          ))}
        </ul>
      ) : feedback ? (
        <span className="p-3 border rounded-2 d-flex flex-column gap-2 aparecer">
          <i className="bi bi-info-circle-fill fs-2 align-self-center font-color-camtom"></i>
          <p className="font-gray-strong">{feedback.feedback}</p>
        </span>
      ) : (
        <p>Aquí verás la clasificación de tu mercancia</p>
      )}
    </div>
  );
};

// Helper function to determine the reliability class
const getConfidenceClass = (confidence) => {
  if (confidence <= 9) return "bg-danger";
  if (confidence <= 49) return "bg-warning-subtle";
  if (confidence <= 69) return "bg-warning";
  if (confidence <= 91) return "bg-success";
  return "bg-info";
};

// Helper function to get confidence text
const getConfidenceText = (confidence) => {
  if (confidence <= 9) return `${confidence}% Sin resultado`;
  if (confidence <= 49) return `${confidence}% Baja confianza`;
  if (confidence <= 69) return `${confidence}% Confianza media`;
  if (confidence <= 91) return `${confidence}% Confianza alta`;
  return `${confidence}% Muy alta confianza`;
};

export default TariffResponse;
