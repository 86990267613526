import { useEffect, useState } from "react";
import getServices from "../../../services/getAgentServices";

const TariffMinimasAndAcuerdos = ({ tariff }) => {
  const [tariffResponse, setTariffResponse] = useState(null);

  useEffect(() => {
    const fetchMinimasAndAcuerdos = async () => {
      const endpoint = `/api/v3/tarifa/consulta`;
      const service = new getServices();
      try {
        await service.fetchAllWithUrlTariffColombia(endpoint, tariff);
        const response = service.getResponse();
        console.log(
          response.iva_y_aranceles_colombia[0].PosArancelIva,
          "response de minimas y acuerdos"
        );
        setTariffResponse(response);
      } catch (error) {
        console.error("Error fetching minimas:", error);
      }
    };
    fetchMinimasAndAcuerdos();
  }, [tariff]);

  // Load response from local storage on mount
  useEffect(() => {
    const tariffResponse = localStorage.getItem("tariffResponse");
    if (tariffResponse) {
      setTariffResponse(JSON.parse(tariffResponse));
    }
  }, []);

  // Save response to local storage whenever it changes
  useEffect(() => {
    if (tariffResponse) {
      localStorage.setItem("tariffResponse", JSON.stringify(tariffResponse));
    }
  }, [tariffResponse]);

  // Update savedResponse when response prop changes
  useEffect(() => {
    if (tariff != null) {
      setTariffResponse(tariff);
    }
  }, [tariff]);

  return (
    <div className="d-flex flex-column">
      {/* -------------------------------- */}
      {/* Mínimas obligatorias */}
      <section className="d-flex flex-row gap-4 w-100 mb-2">
        <p className="w-100 fw-semibold text-gray fs-5 text">
          Descripciones mínimas obligatorias
        </p>
      </section>
      <section className="d-flex flex-row flex-wrap gap-2">
        {tariffResponse ? (
          tariffResponse?.minimas_colombia?.map((minima, index) =>
            minima.Obligatoria === 1 ? (
              <small
                className="mb-0 text-gray text border rounded-5 p-2 text-center"
                key={minima + index}
              >
                {minima.NombreMinima}
              </small>
            ) : null
          )
        ) : (
          <p>No podemos acceder a la información</p>
        )}
      </section>
      <hr />
      {/* -------------------------------- */}
      {/* Requisitos */}
      <section className="d-flex flex-column mb-2 bg-blueLigth rounded-3">
        <details>
          <summary className="fw-semibold text-gray p-2">Requisitos</summary>
          <table className="table border-0">
            <thead>
              <tr>
                {tariffResponse?.requisitos_colombia?.[0] &&
                  Object.entries(tariffResponse?.requisitos_colombia[0]).map(
                    ([key, value], index) =>
                      value !== "No" && value !== "-1" ? (
                        <th key={key + index}>{key}</th>
                      ) : null
                  )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {tariffResponse?.requisitos_colombia?.[0] &&
                  Object.entries(tariffResponse?.requisitos_colombia[0]).map(
                    ([key, value], index) =>
                      value !== "No" && value !== "-1" ? (
                        <td key={key + index}>{value}</td>
                      ) : null
                  )}
              </tr>
            </tbody>
          </table>
        </details>
      </section>
      {/* -------------------------------- */}
      {/* Ivas */}
      <section className="d-flex flex-column mb-2 flex-wrap bg-blueLigth rounded-3">
        <details>
          <summary className="fw-semibold text-gray fs-5 p-2">
            Ivas y Aranceles
          </summary>
          <span className="w-100 d-flex flex-row align-items-start">
            <small className="w-50 mb-0 text-gray text text-center align-self-center">
              IVA
            </small>
            <small className="w-50 mb-0 text-gray text p-2 text-center align-self-center">
              Unidad Comercial
            </small>
          </span>
          <span className="w-100 d-flex flex-row align-items-start">
            <small className="w-100 mb-0 text-gray p-2 text-center fs-07">
              {tariffResponse?.iva_y_aranceles_colombia?.[0]?.PosArancelIva ??
                "Información no disponible"}
            </small>
            <small className="w-100 mb-0 text-gray p-2 text-center fs-07">
              {tariffResponse?.iva_y_aranceles_colombia?.[0]
                ?.UnidadComercialID ?? "Información no disponible"}
            </small>
          </span>
        </details>
      </section>
      {/* -------------------------------- */}
      {/* Acuerdos */}
      <section className="d-flex rounded-3 p-2 flex-row align-items-center gap-4 w-100 mb-2 bg-blueLigth">
        <details>
          <summary className="w-100 fw-semibold text-gray fs-5">
            Acuerdos comerciales
          </summary>
          <section className="d-flex flex-row flex-wrap justify-content-center gap-2">
            {tariffResponse?.acuerdos_colombia?.map((acuerdo, index) => (
              <span className="w-20 p-2 d-flex border flex-column rounded-3">
                <small
                  className="mb-0 text-gray rounded-5 p-2 text-center align-self-center text-uppercase"
                  key={acuerdo + index}
                >
                  {acuerdo.PaisAcuerdo}
                </small>
                <small
                  className="mb-0 text-gray p-2 text-center fs-07"
                  key={index}
                >
                  Acuerdo: {acuerdo.NormaAcuerdo}
                </small>
              </span>
            ))}
          </section>
        </details>
      </section>
    </div>
  );
};

export default TariffMinimasAndAcuerdos;
