import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import * as XLSX from "xlsx";

import "./TariffPro.css";
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";
import LoadingCamtom from "../../../../Modals/LoadingCamtom.js";
import TariffXlsx from "../../tariffCommon/TariffXlsx.js";

const TariffProXlsx = ({ countryClasiffication }) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.token);
  // Idioma
  const { t } = useTranslation();

  // Consultar Fracción desde el hijo
  const handleSuccess = (result) => {
    const blobUrl = window.URL.createObjectURL(result); // Creando una URL temporal
    setResponse(blobUrl); // Guardando la URL en el estado
    // console.log("Archivo listo para descargar:", blobUrl);
  };

  useEffect(() => {
    if (response) {
      // console.log("URL del archivo actualizada:", response);
    }
  }, [response]);

  return (
    <>
      {loading ? (
        <LoadingCamtom />
      ) : (
        <div className="TariffPro">
          <div className="main-content">
            <div className="classifier-section">
              <TariffXlsx
                onSuccess={handleSuccess}
                token={token}
                setLoading={setLoading}
                countryClasiffication={countryClasiffication}
              />

              {/* {!response && <p>Aqui veras tu clasificación.</p>} */}
              {loading && <p>{t("loading_p")}</p>}
              <br />
              {response && (
                <div className={response ? "product-uploaded" : ""}>
                  <a href={response} download="archivo.xlsx">
                    ⬇️ {t("xlsx_download")}
                  </a>
                  {/* <span onClick={handleExitXlsxtBtn}>
                    {file && <i className="bi bi-x-lg font-gray-strong"></i>}
                  </span> */}
                </div>
              )}
            </div>
          </div>
          <Toaster position="top-center" reverseOrder={false} />
        </div>
      )}
    </>
  );
};

export default TariffProXlsx;
