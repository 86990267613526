import { useEffect, useState } from "react";
import getServices from "../../../services/getAgentServices";
import { store } from "../../../redux/store";

function HistoryView() {
  const service = new getServices();
  const state = store.getState();
  const customsAgencyEmployeeId = state.agent.customsAgencyEmployeeId;
  const [data, setData] = useState();
  const [filesS3, setFilesS3] = useState({});
  const [modelResult, setModelResult] = useState([]);
  const URL_BASE = process.env.REACT_APP_serverURL;

  const fetchDataHistory = async () => {
    const endpoint = `/classificationV2/customsAgency/${customsAgencyEmployeeId}`;
    console.log(customsAgencyEmployeeId);
    try {
      await service.fetchAll(endpoint);
      const response = service.getResponse();
      console.log(response);
      //sort by most recent day
      const sortedData = response.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );

      setData(sortedData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFile = async (filePath) => {
    const URL_s3 = `${process.env.REACT_APP_BUCKET}/`; // url base modelo para cortar url de filePath
    const url = "/getFileByPath";
    const filePathPart = filePath.replace(URL_s3, ""); // cortando la parte necesaria

    console.warn(filePath, "parte");
    try {
      const response = await fetch(
        `${URL_BASE}/${url}?filepath=${filePathPart}`
      );
      console.log(
        `${URL_BASE}/classificationV2${url}?filepath=${filePathPart}`
      );
      if (response.ok) {
        const contentType = response.headers.get("Content-Type");
        const fileBlob = await response.blob();

        if (contentType.includes("pdf")) {
          // Para archivos PDF: abrir en nueva pestaña
          const fileUrl = URL.createObjectURL(fileBlob);
          window.open(fileUrl, "_blank");
        } else if (contentType.includes("spreadsheetml")) {
          // Para archivos XLSX: forzar descarga
          const fileUrl = URL.createObjectURL(fileBlob);
          const a = document.createElement("a");
          a.href = fileUrl;
          a.download = "archivo.xlsx"; // Nombre por defecto para el archivo
          a.click(); // Iniciar la descarga
        } else {
          console.error("Tipo de archivo no soportado:", contentType);
        }
      } else {
        console.error("Error al obtener el archivo:", response.statusText);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  useEffect(() => {
    fetchDataHistory();
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      const dataToFilter = data.map((el) => {
        try {
          return JSON.parse(el.modelResult) || [];
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return []; // Return an empty array or handle the error as needed
        }
      });
      setModelResult(dataToFilter);
    }
  }, [data]);

  return (
    <section className="d-flex flex-column w-100 gap-3">
      <h1 className="my-5 ms-4">Historial de clasificaciones</h1>
      {/* Table thead */}
      <div className="w-75 d-flex flex-row gap-3 align-self-center">
        <span className="w-20 text-center fw-bold">
          <p className="mb-0 pb-0">Descripción</p>
        </span>
        <span className="w-20 text-center fw-bold">
          <p className="mb-0 pb-0">Tipo</p>
        </span>
        <span className="w-20 text-center fw-bold">
          <p>Fracción</p>
        </span>
        <span className="w-20 text-center fw-bold">
          <p>País</p>
        </span>
        <span className="w-20 text-center fw-bold">
          <p>Input</p>
        </span>
        <span className="w-20 text-center fw-bold">
          <p>Fecha</p>
        </span>
      </div>
      {/* table tbody */}
      {data?.length > 0 ? (
        data?.map((el, index) => (
          <div
            className="w-75 d-flex flex-column align-self-center"
            key={index}
          >
            <div className="w-100 d-flex flex-row gap-3">
              {el.predictionMode === "text" ? (
                <span className="w-20 pe-2">
                  <p className="mb-0 pb-0 text-start">{el.description}</p>
                </span>
              ) : (
                <span className="w-20 pe-2">
                  <p className="mb-0 pb-0 text-center">—</p>
                </span>
              )}
              <span className="w-20 pe-2">
                <p className="mb-0 pb-0 text-center">{el.predictionMode}</p>
              </span>
              <span className="w-20 pe-2 d-flex flex-row flex-wrap align-items-end h-100">
                {Array.isArray(modelResult[index]) ? (
                  modelResult[index].map((item, i) => (
                    <p
                      className={`mb-0 pb-0 text-center px-2 border rounded-5 font-camtom-super-small d-flex align-items-center justify-content-center height-fit-content ${
                        item?.overall_confidence >= 0 &&
                        item?.overall_confidence <= 9
                          ? "bg-danger"
                          : item?.overall_confidence > 9 &&
                            item?.overall_confidence <= 49
                          ? "bg-warning-subtle"
                          : item?.overall_confidence > 49 &&
                            item?.overall_confidence <= 69
                          ? "bg-warning"
                          : item?.overall_confidence > 69 &&
                            item?.overall_confidence <= 91
                          ? "bg-success"
                          : item?.overall_confidence > 91 &&
                            item?.overall_confidence <= 100
                          ? "bg-info"
                          : ""
                      }`}
                      key={i}
                    >
                      {item.fraction.code}
                    </p>
                  ))
                ) : (
                  <p className="text-center">{modelResult[index]}</p>
                )}
              </span>

              <span className="w-20 p-0 d-flex flex-column align-items-center justify-content-start">
                <span className="w-100 my-0 py-0 fw-bold text-center fs-3">
                  {el.countryCode === "MX" || !el.countryCode ? "🇲🇽" : "🇨🇴"}
                </span>
              </span>

              <span className="w-20 pe-2">
                {el.predictionMode === "text" ? (
                  <p className="mb-0 pb-0 text-center">{el.inputFilePath}</p>
                ) : el.predictionMode === "img" ? (
                  <p className="mb-0 pb-0 text-center">
                    <a
                      href={el.inputFilePath}
                      target="_blank"
                      download={true}
                      className="text-decoration-none btn-camtom"
                      rel="noreferrer"
                    >
                      Ver {el.predictionMode}
                    </a>
                  </p>
                ) : el.predictionMode === "pdf" ||
                  el.predictionMode === "xlsx" ? (
                  <p className="mb-0 pb-0 text-center">
                    <a
                      href="#"
                      onClick={() => fetchFile(el.inputFilePath)} // Llamar la función con el filePath
                      className="text-decoration-none btn-camtom"
                      rel="noreferrer"
                    >
                      Ver
                    </a>
                  </p>
                ) : null}
              </span>
              <span className="w-20 pe-2">
                <p className="mb-0 pb-0 fw-bold text-center">
                  {new Date(el.timestamp).toLocaleDateString("es-Es")}
                </p>
              </span>
            </div>
            <hr />
          </div>
        ))
      ) : (
        <p className="text-center my-5 font-gray-light">
          No hay clasificaciones guardadas
        </p>
      )}
    </section>
  );
}
export default HistoryView;
