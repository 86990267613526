const flaskApi = process.env.REACT_APP_serverURLtariff

class postDigiterServices {
  constructor() {
    this.response = [];
  }

  getResponse() {
    return this.response;
  }

  async postData(type, formData) {
    const options = {
      method: "POST",
      body: formData,
    };
    try {
      const res = await fetch(`${flaskApi}/api/v3/digiter/${type}`, options);
      const json = await res.json();
      console.log(json);

      this.response = json;
      return json;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export default postDigiterServices;
