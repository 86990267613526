import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase"; // Import your Firebase configuration
import toast, { Toaster } from "react-hot-toast";

import "./RegisterCompanyAgency.css";

const serverURL = process.env.REACT_APP_serverURL;

function RegisterCompanyAgency() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(null);
  const [sliderTypeOperation, setSliderTypeOperation] = useState(false);
  const [reactiveEndpoint, setReactiveEndpoint] = useState(null);

  const onSubmit = async (data) => {
    try {
      setSubmitting(true);
      // enviando datos al firebase
      // Register user in Firebase Authentication
      const { email, password, name, commercialName } = data;
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { uid } = userCredential.user;
      console.log(uid, "uid");
      // Prepare data for POST request to your server
      const requestData = {
        UID: uid,
        email,
        name,
        commercialName,
        subscriptionStatus: "free",
      };

      const headers = {
        // Add any headers needed
      };

      // Send POST request to your server endpoint

      const response = await axios.post(
        `${serverURL}${reactiveEndpoint}`,
        requestData,
        { headers }
      );

      // Handle success response
      console.log(
        "Registration successful:",
        response,
        `${serverURL}${reactiveEndpoint}`
      );

      // Show success alert and clear form
      // setAlert({ type: "success", message: "Registration successful!" });
      toast("Registration successful!", {
        icon: "🚀",
        style: {
          borderRadius: "10px",
          // background: "#333",
          background: "#abefa7",
          color: "#fff",
        },
      });

      reset();
    } catch (error) {
      // Handle error
      console.error("Error registering user:", error);
      // Show error alert
      // setAlert({
      //   type: "error",
      //   message: error.message || "Registration failed. Please try again.",
      // });

      toast.error("Error registering user", {
        icon: "❌",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  const changeTypeOeration = () => {
    setSliderTypeOperation(!sliderTypeOperation);
  };

  useEffect(() => {
    console.log(sliderTypeOperation);
    sliderTypeOperation
      ? setReactiveEndpoint("/api/register/customsAgencyEmployee/")
      : setReactiveEndpoint("/api/register/tradingCompanyEmployee/");
  }, [sliderTypeOperation, setReactiveEndpoint]);

  return (
    <div className="register-user-container">
      {/* {alert && <div className={`alert ${alert.type}`}>{alert.message}</div>} */}

      <form onSubmit={handleSubmit(onSubmit)} className="register-user-form">
        {/* Slide btn */}
        <div className="w-100 d-flex align-items-center">
          <button
            className="slider-btn-container-user position-relative fs-6"
            onClick={changeTypeOeration}
          >
            <span
              className={`btn-slider-user ${
                sliderTypeOperation ? "slide-true-user" : "slide-false"
              }`}
            ></span>
            <span className="d-flex flex-row align-items-center absolute-text">
              <p
                className={`my-0 w-50 ${
                  sliderTypeOperation
                    ? "active-btn-slider"
                    : "inactive-btn-slider"
                }`}
              >
                Agencia
              </p>
              <p
                className={`my-0 w-50 ${
                  sliderTypeOperation
                    ? "inactive-btn-slider"
                    : "active-btn-slider"
                }`}
              >
                Empresa
              </p>
            </span>
          </button>
        </div>
        <label>Email:</label>
        <input type="email" {...register("email", { required: true })} />
        {errors.email && <span className="error">This field is required</span>}

        <label>Password:</label>
        <input type="password" {...register("password", { required: true })} />
        {errors.password && (
          <span className="error">This field is required</span>
        )}

        <label>Name:</label>
        <input type="text" {...register("name", { required: true })} />
        {errors.name && <span className="error">This field is required</span>}

        <label>Commercial Name:</label>
        <input
          type="text"
          {...register("commercialName", { required: true })}
        />
        {errors.commercialName && (
          <span className="error">This field is required</span>
        )}

        <button type="submit" disabled={submitting}>
          {submitting ? "Submitting..." : "Submit"}
        </button>
      </form>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}

export default RegisterCompanyAgency;
