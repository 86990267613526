import React, { useState } from "react";
import "../.././style.css";
import logo from "../../../imagesCommon/camtom-enterprises.svg";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase.js";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../redux/reducers.js";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AcceptCookies from "../../../cookies/AcceptCookies.js";
import logoEmpresa from "../../../imagesCommon/logoEmpresa.png";
import logoRuffo from "../../../imagesCommon/logoRuffo.png";
import { useForm } from "react-hook-form";

import axios from "axios";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

const serverURL = process.env.REACT_APP_serverURL;

function LoginTradingCompany() {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    try {
      // Ensure email is valid
      if (!/\S+@\S+\.\S+/.test(data.username)) {
        throw new Error("Correo inválido");
      }

      // Firebase sign-in
      const userCredential = await signInWithEmailAndPassword(
        auth,
        data.username,
        data.password
      );

      // Get the ID token from Firebase
      const idToken = await userCredential.user.getIdToken();

      // Prepare the request body for your server
      const headers = {
        idToken: idToken,
      };

      // Send the ID token to your server for verification
      const userResponse = await axios.post(
        `${serverURL}/api/login/tradingCompanyEmployee`,
        {}, // This is the data object; include any payload you need to send here.
        { headers } // Pass the headers as the third argument
      );

      // Destructure response data
      const {
        tempToken: token,
        email,
        tradingCompanyId,
        tradingCompanyEmployeeId,
      } = userResponse.data;

      // Set cookies
      Cookies.set("tokenUser", token);
      Cookies.set("email", email);
      Cookies.set("tradingCompanyId", tradingCompanyId);
      Cookies.set("tradingCompanyEmployeeId", tradingCompanyEmployeeId);

      // Prepare user object for Redux
      const user = {
        token,
        email,
        tradingCompanyId,
        tradingCompanyEmployeeId,
      };
      // Dispatch login action
      dispatch(loginUser(user));

      // Success toast notification
      toast("¡Bienvenido a Camtom!", {
        icon: "🚀",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      // Navigate to user dashboard after a brief delay
      setTimeout(() => {
        navigate(`/user-dashboard`);
      }, 350);
    } catch (error) {
      // Error handling for Firebase auth or server-side errors
      const errorMessage =
        error.code === "auth/wrong-password"
          ? "Contraseña incorrecta"
          : error.code === "auth/user-not-found"
          ? "Usuario no encontrado"
          : error.code === "auth/too-many-requests"
          ? "Demasiados intentos fallidos. Tu cuenta está temporalmente inactiva. Cambia tu contraseña para recuperar el acceso o intenta más tarde."
          : error.response?.data || "Error de autenticación";

      // Display error notification
      toast(errorMessage, {
        icon: "❌",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      console.log(error);
    }
  };

  const logoSrc = window.location.href.includes("ruffo") ? logoRuffo : logo;

  return (
    <div className="login-container">
      {/* SECTION IMG DESTACADA */}

      <section class="section-logo">
        <img src={logoEmpresa} alt="logoAgencia" />
      </section>
      {/* SECTION IMG DESTACADA */}

      {/* SECTION LOGIN FORMULARIO */}

      <section class="section-login d-flex align-items-center p-5 padding-left-right-5">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-100 d-flex flex-column gap-2"
        >
          {/* LOGO-ENCABEZADO */}
          <a href="/" className="d-flex flex-column mb-5 text-color-camtom">
            <img className="logo-camtom" src={logoSrc} alt="Logo" />
          </a>

          <h1 className="my-5">Iniciar Sesión</h1>
          {/* LOGO-ENCABEZADO */}

          {/* IMPUT */}
          <div class="d-flex flex-column pb-4">
            <label htmlFor="username" className="pb-4 fw-normal fs-5">
              Correo electrónico:{/* <b>{t("login_email")}</b> */}
            </label>
            <span class="input-border d-flex flex-row">
              <i className="bi bi-person fs-3 me-2"></i>
              <input
                type="text"
                id="username"
                // onChange={(e) => setUsername(e.target.value)}
                className="input-login p-2 w-100"
                {...register("username", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </span>
            {errors.username && (
              <span className="span-error">{errors.username.message}</span>
            )}
          </div>
          {/* IMPUT */}
          <div class="d-flex flex-column pb-4">
            <label htmlFor="password" className="pb-4 fw-normal fs-5">
              Contraseña:{/* <b>{t("login_email")}</b> */}
            </label>
            <span class="input-border d-flex flex-row">
              <i className="bi bi-lock fs-3 me-2"></i>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                // onChange={(e) => setPassword(e.target.value)}
                className="input-login p-2 w-100"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
              {showPassword ? (
                <i
                  className="bi bi-eye-slash lh-3"
                  onClick={handleShowPassword}
                ></i>
              ) : (
                <i className="bi bi-eye lh-3" onClick={handleShowPassword}></i>
              )}
            </span>
            {errors.password && (
              <span className="span-error">{errors.password.message}</span>
            )}
          </div>
          <div className="d-flex flex-row align-items-baseline w-100">
            <button type="submit" className="btn-camtom p-3 w-25 me-5">
              {t("login_btn")}
            </button>
            <p className="text-center w-50">
              <a href="/login/forgot-password" style={{ color: "#004A7C" }}>
                {t("login_question")}
              </a>
            </p>
          </div>
          <Toaster position="top-center" reverseOrder={false} />
          <br />
        </form>
        <AcceptCookies />
      </section>
    </div>
  );
}

export default LoginTradingCompany;
