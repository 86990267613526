import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOutAgent } from "../../../../../redux/reducers";
import Cookies from "js-cookie";
// import logoRuffo from "../../../../imagesCommon/logoRuffo_white.png";
// import logoCamtom from "../../../../imagesCommon/Logo.svg";
// import logoSingle from "../../../../imagesCommon/logo-single.svg";

import { useTranslation } from "react-i18next";
import "../../../../style/Sidebar.css";

const Sidebar = ({ openClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(openClose);

  const isActive = (path) => {
    return window.location.pathname === path ? "active" : "";
  };

  const handleLogout = () => {
    // Clear cookies
    Cookies.remove("tokenAgent");
    // Dispatch logout action
    dispatch(logOutAgent());
    // Redirect to login page
    navigate("/login/customs-agency");
  };

  useEffect(() => {
    setIsOpen(openClose);
  }, [isOpen, openClose]);

  return (
    <div className="mt-3">
      <nav className={`menu ${isOpen ? "align-start" : "align-center"}`}>
        <Link
          to="/agent-dashboard"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/agent-dashboard"
            )} ${
              isOpen
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-house-door fs-4"></i>
            <p className={`m-0 ${isOpen ? "d-block" : "d-none"}`}>
              {t("sidebar_home")}
            </p>
          </div>
          {/* HOME */}
        </Link>

        <Link
          to="/agent-dashboard/tariff-pro-companies"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/agent-dashboard/tariff-pro-companies"
            )} ${
              isOpen
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-people-fill fs-4"></i>
            <p className={`m-0 ${isOpen ? "d-block" : "d-none"}`}>
              {t("sidebar_companies")}
            </p>
          </div>
        </Link>

        <Link
          to="/agent-dashboard/tariff-pro-clasifications-database"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/agent-dashboard/tariff-pro-clasifications-database"
            )} ${
              isOpen
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-database fs-4"></i>
            <p className={`m-0 text break ${isOpen ? "d-block" : "d-none"}`}>
              {t("sidebar_database")}
            </p>
          </div>
        </Link>
        {/* Historial */}
        <Link
          to="/agent-dashboard/history"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/agent-dashboard/history"
            )} ${
              isOpen
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-collection fs-4"></i>
            <p className={`m-0 text break ${isOpen ? "d-block" : "d-none"}`}>
              Historial
            </p>
          </div>
        </Link>

        <Link
          to="/agent-dashboard/tariff-pro"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/agent-dashboard/tariff-pro"
            )} ${
              isOpen
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-search fs-4"></i>
            <p className={`m-0 text break ${isOpen ? "d-block" : "d-none"}`}>
              {t("sidebar_clasifier")}
            </p>
          </div>
        </Link>

        {/* DIGITER */}

        <Link to="/agent-dashboard/digiter" className="menu-item">
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/agent-dashboard/digiter"
            )} ${
              isOpen
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-file-earmark-text fs-4"></i>
            <p className={`m-0 ${isOpen ? "d-block" : "d-none"}`}>
              Digitalizador
            </p>
          </div>
        </Link>

        <div className="divider"></div>

        <Link
          to="/agent-dashboard/billing"
          className="menu-item"
          // onClick={handleIsToken}
        >
          <div
            className={`sidebar-list d-flex flex-row ${isActive(
              "/agent-dashboard/billing"
            )} ${
              isOpen
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-cash fs-4"></i>
            <p
              className={`m-0 letter-spacing text break ${
                isOpen ? "d-block" : "d-none"
              }`}
            >
              Billing
            </p>
          </div>
        </Link>

        <a href="https://camtomx.com" className="menu-item mb-5" target="blank">
          <div
            className={`sidebar-list d-flex flex-row ${
              isOpen
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
          >
            <i className="bi bi-headset fs-4"></i>
            <p className={`m-0 ${isOpen ? "d-block" : "d-none"}`}>
              {t("sidebar_service")}
            </p>
          </div>
        </a>
        <Link
          to="/agent-dashboard/new-simulations"
          className="menu-item btn-new-simulations"
          // onClick={handleIsToken}
        >
          <div className={`d-flex align-items-center justify-content-center`}>
            <i className="bi bi-plus fs-4"></i>
            <p className={`m-0 ${isOpen ? "d-block" : "d-none"}`}>
              {t("sidebar_new_simulations")}
            </p>
            {/* <i className="bi bi-lock fs-5"></i> */}
          </div>
        </Link>

        <div
          className={`sidebar-logout ${
            isOpen ? "justify-content-start" : "justify-content-center m-0"
          }`}
        >
          <div
            className={`d-flex flex-row align-items-center justify-content-center ${
              isOpen
                ? "justify-content-start gap-3"
                : "justify-content-center m-0"
            }`}
            onClick={handleLogout}
          >
            <i className="bi bi-box-arrow-right fs-4"></i>
            <p className={`m-0 transition ${isOpen ? "d-block" : "d-none"}`}>
              {t("sidebar_logout")}
            </p>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
