import { useForm } from "react-hook-form";
import "./Digiter.css";
import { useState } from "react";
import postDigiterServices from "../../../services/postDigiterServices";

const Digiter = () => {
  const [resultLink, setResultLink] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado
  const [fileNames, setFileNames] = useState([]); // Capturando el nombre de los archivos seleccionados

  //Funciones del form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      nArchivos: "single-file", // Valor por defecto para nArchivos
    },
  });

  // console.log(companiesData);

  const onSubmit = async (data) => {
    setResultLink("")
    const typeOperation = data.nArchivos || "single-file";
    const files = data.file; // Handling multiple files

    setIsSubmitting(true);

    const formData = new FormData();

    // Loop through each file if multiple are selected
    Array.from(files).forEach((file) => {
      formData.append("file", file);
    });

    try {
      const service = new postDigiterServices();
      await service.postData(typeOperation, formData);
      const response = service.getResponse(); 

      setResultLink(response.url_file);
      reset();
    } catch (error) {
      alert("Error uploading files");
      console.error("Error uploading files:", error);
    } finally {
      setIsSubmitting(false);
      setFileNames([]);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFileNames(files.map((file) => file.name));
    //console.log(fileNames);
  };

  return (
    <div className="mb-3 p-5 digiter">
      <h2>
        Digitaliza tus archivos a una resolución de 300 DPI, listos para subir a la VUCEM.
      </h2>
      <p>
        Si alguno de tus archivos excede el límite permitido por la VUCEM, nuestro sistema los dividirá automáticamente en subarchivos comprimidos en un archivo .zip, facilitando así su carga.
      </p>
      <form
        className="d-flex flex-column gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h3>1. Elige una opción para digitalizar</h3>

        <div className="d-flex flex-column gap-3 p-3">
          <label htmlFor="nArchivos" className="d-flex align-items-center">
            <input
              type="radio"
              id="nArchivos"
              value="single-file"
              className="me-2"
              {...register("nArchivos", { required: true })}
            />
            Un solo archivo
          </label>

          <label className="d-flex align-items-center">
            <input
              type="radio"
              value="multiple-files"
              className="me-2"
              {...register("nArchivos", { required: true })}
            />
            Múltiples archivos
          </label>

          <label className="d-flex align-items-center">
            <input
              type="radio"
              value="join-pdfs"
              className="me-2"
              {...register("nArchivos", { required: true })}
            />
            Juntar múltiples archivos en uno solo
          </label>

          {errors.option && <p>This field is required</p>}
        </div>

        <h3 className="mb-3">
          2. Sube el archivo(s) que quieres convertir (Formato PDF)
        </h3>
        <div class="d-flex flex-column gap-1">
          {fileNames?.map((el, index) => (
            <span
              className="d-flex flex-row align-items-center w-100 p-2 border rounded"
              key={index}
            >
              <i className="bi bi-check-circle-fill fs-3 text-success me-2"></i>
              <p class="m-0">{el}</p>
            </span>
          ))}
        </div>
        <div className="d-flex flex-column gap-3 p-3 position-relative rounded bg-input-file mb-5">
          <label htmlFor="file" className="d-flex align-items-center">
            <input
              type="file"
              name="file"
              id="file"
              className="w-100 opacity-0"
              multiple
              accept="application/pdf, .pdf" // Accept only PDF files
              {...register("file", { required: true })}
              onChange={handleFileChange}
            />
            <span className="position-absolute d-flex flex-row justify-content-center align-items-center m-0 w-100">
              <i className="bi bi-plus fs-3 me-2spam"></i>
              <p className="m-0">Subir un archivo</p>
            </span>
          </label>

          {errors.file && <p>Error converting file</p>}
        </div>
        <button type="submit" className="align-self-center p-3 w-25 btn-camtom mb-5">
          {isSubmitting ? "Procesando..." : "Convertir"}
        </button>
      </form>
      {/* LISTA DE ARCHIVOS CARGADOS */}

      {/* MENSAJE EXITOSO */}
      {resultLink && (
        <section class="d-flex flex-column align-items-center justify-content-center border border-2 mb-5 p-3">
          <i className="bi bi-check-circle-fill fs-1 text-success"></i>
          <p className="m-0">Los archivos se convirtieron correctamente</p>
          <a
            href={resultLink}
            className="a-not-underlined"
            target="_blank"
            rel="noopener noreferrer"
          >
            Haz click aquí para descargarlos
          </a>
        </section>
      )}
        <br/>
        <br/>
        <br/>
    </div>
  );
};
export default Digiter;
