import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import App from "./components/App";


import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store.js";

ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById("root")
);
