import loaginImage from "../imagesCommon/Vector-1.svg";
import cloud from "../imagesCommon/cloud.svg";
import { useTranslation } from "react-i18next";

function LoadingCamtom() {
  const { t } = useTranslation(); // i18n // traduccion
  // ESTILOS DE LOADING EN LA CARPETA STYLE DE LA RAIZ => QUOTER.CSS
  return (
    <div class="d-flex flex-column align-items-center justify-content-center gap-4 overflow-hidden loading-screen-plane w-100 height-inherit">
      <div class="position-relative up">
        <img
          src={loaginImage}
          alt=""
          className="w-50 animation-loading pt-5 plane"
        />
        <img src={cloud} alt="" className="position-ab w-50 cloud-front" />
        <img src={cloud} alt="" className="position-ab w-25 cloud-back" />
        <img src={cloud} alt="" className="position-ab cloud-big" />
      </div>
      <h4 className="w-75 text-center z-100 font-gray-strong">
        {t("loading_message")}
      </h4>
    </div>
  );
}

export default LoadingCamtom;
