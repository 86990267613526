const URL_TARIFF = process.env.REACT_APP_serverURLtariff;

class getNearlyService {
  constructor() {
    this.response = [];
  }

  getResponse() {
    return this.response;
  }
  // Buscamos los cercanos de cada tarifa
  async getNearly(fraction, countryCode) {
    const endpoint = "/api/v3/get_fractions_nearly";
    const queryParams = new URLSearchParams({
      fraccion: fraction,
      country_code: countryCode,
    });

    const url = `${URL_TARIFF}${endpoint}?${queryParams.toString()}`;
    try {
      const res = await fetch(url);
      const json = await res.json();

      this.response = json;
      // console.log(url, json);
      // return json;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export default getNearlyService;
