import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase"; // Ensure firebase is properly initialized
import { sendPasswordResetEmail } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import logoEmpresa from "../../imagesCommon/logoEmpresa.png";
import AcceptCookies from "../../cookies/AcceptCookies";

function ForgotPassword() {
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      // Validate email format
      if (!/\S+@\S+\.\S+/.test(data.email)) {
        throw new Error("Correo inválido");
      }

      // Firebase password reset email
      await sendPasswordResetEmail(auth, data.email);

      // Notify the user
      toast.success("Correo de recuperación enviado", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      // Update state to show success message
      setEmailSent(true);
    } catch (error) {
      const errorMessage =
        error.code === "auth/user-not-found"
          ? "Correo no registrado"
          : "Error al enviar el correo";

      // Display error toast
      toast.error(errorMessage, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  return (
    <div className="forgot-password-container">
        <button
        className="btn p-2 m-2"
        onClick={() => navigate(-1)} // Regresa a la página anterior
        >
        ← Regresar
        </button>
      <section className="section-forgot-password d-flex align-items-center p-5">
        {emailSent ? (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                textAlign: "center" // Para centrar el texto dentro del div
                }}>
                <div>
                    <h2>{t("Revisa tu bandeja de email")}</h2>
                    <p>
                    {t("Te hemos enviado un mensaje con las instrucciones para restablecer tu contraseña. Podría estar en no deseados o spam.")}
                    </p>
                    <button
                        className="btn-camtom p-2 m-2"
                        onClick={() => navigate('/')} // Regresa a la página anterior
                        >
                        Login
                    </button>
                </div>
            </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="w-100 d-flex flex-column gap-2">
            <h1 className="my-5">{t("¿Olvidaste tu contraseña?")}</h1>

            <div className="d-flex flex-column pb-4">
              <label htmlFor="email" className="pb-4 fw-normal fs-5">
                Correo electrónico:
              </label>
              <span className="input-border d-flex flex-row">
                <i className="bi bi-envelope fs-3 me-2"></i>
                <input
                  type="text"
                  id="email"
                  className="input-forgot-password p-2 w-100"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Este campo es obligatorio",
                    },
                  })}
                />
              </span>
              {errors.email && <span className="span-error">{errors.email.message}</span>}
            </div>

            <p className="mt-3 mb-4">
              Si el correo electrónico está registrado en nuestro sistema, te llegará un correo con instrucciones para restablecer tu contraseña.
            </p>

            <button type="submit" className="btn-camtom p-3 w-50">
              {t("Enviar correo")}
            </button>

            <Toaster position="top-center" reverseOrder={false} />
          </form>
        )}
        <AcceptCookies />
      </section>
    </div>
  );
}

export default ForgotPassword;
