import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

function TariffTxt({
  onSuccess,
  token,
  setLoading,
  countryClasiffication,
  ID,
}) {
  const { t } = useTranslation();
  const BASE_URL = process.env.REACT_APP_serverURLtariff;
  const [blockBtn, setBlockBtn] = useState(false);

  const {
    register,
    formState: { errors },
    getValues,
    // control,
  } = useForm();

  // Consultar Fracción
  const handleFraction = async () => {
    setBlockBtn(!blockBtn);
    setLoading(true);
    const toastFracction = toast("Procesando...", {
      icon: "⏳ ",
      style: {
        borderRadius: "10px",
        background: "#fff",
        color: "#000",
      },
      duration: Infinity,
    });
    const url = `${BASE_URL}/api/v2/text-image`;

    // console.log(url);

    try {
      const values = getValues(); // Obtener todos los valores registrados en el formulario
      const prompt1 = values.prompt1;
      const prompt2 = values.prompt2;
      let body = {};

      body.countryCode = countryClasiffication; // la prop de pais que viene del sidebar (usar COL O MX)
      body.tradingCompanyEmployeeId = ID; // Vincular la clasif con el usuario

      if (prompt1 || prompt2) {
        body.userDescription = `${prompt1} ${prompt2}`;
        // console.log(body);
      }

      const options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(url, options);
      const res = await response.json();
      // console.log(res, response, "respuesta");
      toast.dismiss(toastFracction);

      if (response.ok || response.status === 422) {
        // A veces viene un mensaje de error en una respuesta.ok
        toast.success(res.message || res.error || "Proceso completado", {
          id: toastFracction,
          duration: 5000,
        });

        onSuccess(res);
        setBlockBtn(false);
        setLoading(false);
      } else {
        toast.error(res.message || res.error, {
          icon: "❗❗ ",
          id: toastFracction,
          duration: 5000,
        });
        setLoading(false);
      }
      // Pasar al siguiente step
    } catch (error) {
      console.log(error, "error");

      toast.dismiss(toast.error);

      toast.error(error, {
        icon: "❗❗",
        id: toastFracction,
        duration: 5000,
      });
      setLoading(false);
    }
  };

  const handleToast = () => {
    // Toast para avisar que ha va a clasificar en X pais
    const toastCountry = toast(
      `Vas a clasificar bajo las normas ${
        countryClasiffication === "COL" ? "colombianas" : "mexicanas"
      }`,
      {
        // icon: "😉 ",
        style: {
          borderRadius: "10px",
          background: `${
            countryClasiffication === "COL"
              ? "linear-gradient(to bottom, #fbe964 33.33%, #fbe964 33.33%, #648bfb 66.66%, #648bfb 66.66%, #fb6464 100%)"
              : "linear-gradient(to left, #e95252 0%, #ffffff 40%, #ffffff 50%, #70e952 100%)"
          }`,
          textShadow: "2px 2px 4px rgba(0.6, 0.6, 0.6, 0.6)",
          color: "#fff",
          textAlign: "center",
        },
      }
    );
  };
  const handleToastAndFraction = () => {
    handleToast();

    setTimeout(() => {
      handleFraction();
    }, 2000);
  };

  return (
    <>
      <div className="description-instructions mt-5">
        <h5 className="my-3">1. ¿Qué es el producto?</h5>
        <input
          type="text"
          name="url_field"
          placeholder={t("txt_instruction_1_placeholder")}
          {...register(`prompt1`, {
            required: {
              value: true,
              message: t("required_message"),
            },
          })}
        />
      </div>
      {errors.prompt1 && <span className="span-error">{errors.message}</span>}
      <div className="description-instructions">
        <h5 className="my-3">2. ¿Qué hace el producto?</h5>
        <input
          type="text"
          name="url_field"
          {...register(`prompt2`, {
            required: {
              value: true,
              message: t("required_message"),
            },
          })}
          placeholder={t("txt_instruction_2_placeholder")}
        />
        {errors.prompt2 && <span className="span-error">{errors.message}</span>}
      </div>

      {/* <h5 className="my-3">3. Selecciona las opciones que correspondan:</h5>
      <div class="d-flex p-1 gap-3 my-4">
        <button className="btn-small-camtom p-2">Materias primas</button>
        <button className="btn-small-camtom p-2">Aparatos electrónicos</button>
        <button className="btn-small-camtom p-2">Artesanales</button>
        <button className="btn-small-camtom p-2">Textiles</button>
        <button className="btn-small-camtom p-2">Bebidas Alcohólicas</button>
      </div> */}

      {/* <span className="d-flex align-items-center justify-content-start rounded-2 p-2 bg-warn">
        <h3>⚠️</h3>
        <small>
          Recuerda que mientras más infarmación aportes a nuestro clasificador,
          tendrás una mayor asertividad
        </small>
      </span> */}
      <div class="w-100 d-flex flex-column align-items-center justify-content-center mt-4">
        <button
          className="btn-camtom align-self-center"
          onClick={handleToastAndFraction}
          disabled={blockBtn}
        >
          {t("btn_get_ranking")}
        </button>
      </div>
    </>
  );
}

export default TariffTxt;
