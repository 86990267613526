import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import "../../../style/Quoter.css";
import countriesCode from "../../../resources/countriesCode.json";
import { useTranslation } from "react-i18next";
import postServices from "../../../../services/PostAgencyServices";
import LoadingCamtom from "../../../../Modals/LoadingCamtom";
import QuoterInfoView from "./QuoterInfoView";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";

function AddQuoterView() {
  const [sliderTypeOperation, setSliderTypeOperation] = useState(false);
  const [slideSavedFraction, setSlideSavedFraction] = useState(false);
  const [changeToSeparateValues, setChangeToSeparateValues] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [data, setData] = useState(null);
  const [typeOperation, setTypeOperation] = useState(null);
  const [showResponse, setShowResponse] = useState(false);
  const { t } = useTranslation(); // i18n // traduccion
  const location = useLocation();
  const { key: fraction } = location.state || {}; // Recuperando la fraccion del clasificador si la hay
  // console.log(fraction, "fraction de clasificador");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  const onSubmit = async (data) => {
    setShowLoading(true);

    const dataToPost = {
      country: data.country,
      impo_or_expo: sliderTypeOperation ? "expo" : "impo",
      products: data.products.map((product) => ({
        valueMX: null,
        valueUSD: Number(product.valueUSD),
        tariff_and_nico: fraction || product.tariff_and_nico,
      })),
    };

    // console.log(dataToPost, data.products);

    const services = new postServices();
    try {
      await services.postDataWithToken(dataToPost); //Enviando datos al servicio
      const response = services.getResponse(); // Obteniendo la respuesta del servicio

      setTypeOperation(dataToPost.impo_or_expo);
      setData(response);
      setShowResponse(true);
      setShowLoading(false);
      //
    } catch (err) {
      // ALERTAS DE ERRORES
      toast(err.message, {
        icon: "❗❗ ",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setTimeout(() => {
        setShowLoading(false);
      }, 1000);
      setShowResponse(false);
      //reset(); Do not reset when an error pops up, as it becomes tedious for the user.
      console.log(err);
    }
  };

  const changeTypeOeration = () => {
    setSliderTypeOperation(!sliderTypeOperation);
  };

  const toggleFractionSavedOrManual = () => {
    setSlideSavedFraction(!slideSavedFraction);
  };
  const toggleChangeToSeparateValues = () => {
    setChangeToSeparateValues(!changeToSeparateValues);
  };

  const addProduct = () => {
    append({ valueUSD: "", tariff_and_nico: "" });
  };

  const handleReturn = () => {
    setShowResponse(false);
  };

  useEffect(() => {
    // console.log(sliderTypeOperation);
    if ((fields.length === 0 && fraction) || fields.length === 0) {
      append({ valueUSD: "", tariff_and_nico: fraction }); // INICIALIZA UN PRIMER GRUPO
    }
  }, [sliderTypeOperation, append, fields, fraction]);

  useEffect(() => {}, [slideSavedFraction]);

  return (
    <>
      {showLoading ? (
        <LoadingCamtom />
      ) : showResponse ? (
        <QuoterInfoView
          response={data}
          typeOperation={typeOperation}
          handleReturn={handleReturn}
        />
      ) : (
        <div className="d-flex flex-column p-5 gap-2 w-100">
          <h1>{t("sidebar_new_simulations")}</h1>
          <h5 className="strong pt-4 fs-6">{t("operation_information")}</h5>
          <p className="fs-6">{t("operation_type")}</p>

          {/* Slide btn */}
          <div class="w-75 d-flex align-items-center">
            <button
              className="slider-btn-container position-relative fs-6"
              onClick={changeTypeOeration}
            >
              <span
                className={`btn-slider ${
                  sliderTypeOperation ? "slide-true" : "slide-false"
                }`}
              ></span>
              <span className="d-flex flex-row align-items-center absolute-text">
                <p
                  className={`my-0 w-50 ${
                    sliderTypeOperation
                      ? "active-btn-slider"
                      : "inactive-btn-slider"
                  }`}
                >
                  {t("expo")}
                  <i className="bi bi-arrow-up-right ms-2"></i>
                </p>
                <p
                  className={`my-0 w-50 ${
                    sliderTypeOperation
                      ? "inactive-btn-slider"
                      : "active-btn-slider"
                  }`}
                >
                  {t("impo")}
                  <i className="bi bi-arrow-down-left ms-2"></i>
                </p>
              </span>
            </button>
          </div>
          <form
            className="d-flex flex-column mt-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-3">
              <label htmlFor="paisOrigen" className="form-label fs-6">
                {sliderTypeOperation
                  ? t("country_of_origin")
                  : t("destination_country")}
              </label>
              <select
                className="form-control my-2 fs-6"
                id="paisOrigen"
                name="paisOrigen"
                disabled
              >
                <option value="">México</option>
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="paisDestino" className="form-label fs-6">
                {sliderTypeOperation
                  ? t("destination_country")
                  : t("country_of_origin")}
              </label>
              <select
                className="form-control my-2 fs-6"
                id="paisDestino"
                name="paisDestino"
                {...register("country", {
                  required: {
                    value: true,
                    message: t("required_message"),
                  },
                })}
              >
                <option value="">{t("select_country")}</option>

                {countriesCode.countries?.map((el, index) => (
                  <option value={el?.code} key={index}>
                    {el?.name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <span className="span-error">{errors.country.message}</span>
              )}
            </div>

            {/* <div className="mb-3">
              <label htmlFor="aduanaArribo" className="form-label fs-6">
                {sliderTypeOperation
                  ? "Aduana Mexicana de Arribo"
                  : "Aduana Mexicana de Salida de la Mercancía "}
              </label>
              <select
                className="form-control my-2 fs-6"
                id="aduanaArribo"
                name="aduanaArribo"
                {...register("aduanaArribo", {
                  required: {
                    value: true,
                    message: t("required_message"),
                  },
                })}
              >
                <option value="">{t("select_country")}</option>

                <option></option>
              </select>
              {errors.aduanaArribo && (
                <span className="span-error">
                  {errors.aduanaArribo.message}
                </span>
              )}
            </div> */}

            <h5 className="my-4 strong fs-5">{t("merchandise_info")}</h5>
            <p className="fs-5 strong my-3 mb-4">{t("tariff_fraction")}</p>

            {fields.map((item, index) => (
              <div key={item.id} className="mb-4">
                <span className="d-flex flex-row gap-2">
                  <small className="strong font-gray-light">
                    {`Partida ${index + 1}`}
                  </small>
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className="btn btn-danger btn-sm ms-auto"
                  >
                    Eliminar
                  </button>
                </span>
                <hr className="hr" />
                <div className="mb-3 d-flex flex-column">
                  <span className="w-100 d-flex justify-content-between">
                    <label
                      htmlFor={`products[${index}].tariff_and_nico`}
                      className="form-label"
                    >
                      {t("tariff_fraction")}
                    </label>
                    {/* <span
                      className="btn-camtom-light font-camtom-small text-center cursor-pointer"
                      onClick={() => toggleFractionSavedOrManual()}
                    >
                      {slideSavedFraction
                        ? "Seleccionar guardada"
                        : "Ingresar manualmente"}
                      <i className="bi bi-arrow-repeat fs-5 ms-2"></i>
                    </span> */}
                  </span>
                  <span>
                    Ingresar Fracción Arancelaria Manualmente
                    {/* {slideSavedFraction
                      ? "Ingresar Fracción Arancelaria Manualmente"
                      : "Ingresar Fracción Arancelaria Previamente Guardada"} */}
                  </span>
                  <input
                    type="string"
                    id={`products[${index}].tariff_and_nico`}
                    className="form-control"
                    {...register(`products[${index}].tariff_and_nico`, {
                      minLength: {
                        value: 8,
                        message: "La fraccion debe tener mínimo 8 dígitos",
                      },
                      maxLength: {
                        value: 8,
                        message: "La fraccion no debe tener más de 8 dígitos",
                      },
                      pattern: {
                        value: /^[0-9]/,
                        message: "La fraccion debe ser de 8 digitos",
                      },
                      required: {
                        value: true,
                        message: t("required_message"),
                      },
                    })}
                  />
                  {/* {slideSavedFraction ? (
                  ) : (
                    <select name="" id="" className="form-control my-2 fs-6">
                      <option value="">ddddddd</option>
                      <option value="">ddddddd</option>
                      <option value="">ddddddd</option>
                      <option value="">ddddddd</option>
                    </select>
                  )} */}
                  {errors.products &&
                    errors.products[index] &&
                    errors.products[index].tariff_and_nico && (
                      <span className="span-error">
                        {errors.products[index].tariff_and_nico.message}
                      </span>
                    )}
                </div>
                <div className="mb-3 d-flex flex-column">
                  <span className="w-100 d-flex justify-content-between">
                    <label
                      htmlFor={`products[${index}].valueUSD`}
                      className="form-label"
                    >
                      {t("merchandise_value")}
                    </label>
{/*                     <span
                      className="btn-camtom-light font-camtom-small text-center cursor-pointer"
                      onClick={() => toggleChangeToSeparateValues()}
                    >
                      {changeToSeparateValues
                        ? "Cambiar a Valores Separados"
                        : "Cambiar a Valor Unitario"}
                      <i className="bi bi-currency-exchange fs-5 ms-2"></i>
                    </span> */}
                  </span>
                  <>
                    Valor Total en Aduana ($US Dollar)
                    <input
                      type="number"
                      id={`products[${index}].valueUSD`}
                      className="form-control"
                      {...register(`products[${index}].valueUSD`, {
                        required: {
                          value: true,
                          message: t("required_message"),
                        },
                      })}
                    />
                  </>
                  {/* {changeToSeparateValues ? (
                    <>
                      Valor Total en Aduana ($US Dollar)
                      <input
                        type="number"
                        id={`products[${index}].valueUSD`}
                        className="form-control"
                        {...register(`products[${index}].valueUSD`, {
                          required: {
                            value: true,
                            message: t("required_message"),
                          },
                        })}
                      />
                    </>
                  ) : (
                    <div className="w-100 d-flex flex-column gap-2">
                      <span class="w-100 d-flex flex-row gap-2 mt-2">
                        <div className="w-50 d-flex flex-column">
                          <small>Valor de la Mercancía ($US Dollar)</small>
                          <input type="number" className="form-control" />
                        </div>

                        <div className="w-50 d-flex flex-column">
                          <small>Costo del Flete Primario ($US Dollar)</small>
                          <input type="number" className="form-control" />
                        </div>
                      </span>
                      Valor Total en Aduana ($US Dollar)
                      <input
                        type="number"
                        id={`products[${index}].valueUSD`}
                        className="form-control"
                        {...register(`products[${index}].valueUSD`, {
                          required: {
                            value: true,
                            message: t("required_message"),
                          },
                        })}
                      />
                    </div>
                  )} */}
                  {errors.products &&
                    errors.products[index] &&
                    errors.products[index].valueUSD && (
                      <span className="span-error">
                        {errors.products[index].valueUSD.message}
                      </span>
                    )}
                </div>
              </div>
            ))}

            <button
              type="button"
              onClick={addProduct}
              className="btn-camtom-light align-self-center"
            >
              {t("add_product")}
            </button>

            <br />
            <button
              type="submit"
              className="btn-camtom w-25 align-self-center mb-4"
            >
              {t("quote_customs_clearance")}
            </button>
          </form>
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default AddQuoterView;
