import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

function TariffXlsx({ onSuccess, token, countryClasiffication, setLoading }) {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const serverUrl = process.env.REACT_APP_serverURLtariff;

  // console.log(countryClasiffication, "desde tariff common");
  const [blockBtn, setBlockBtn] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    // console.log(e.target.files[0]);
  };

  const handlePostToApi = async () => {
    const apiUrl = `${serverUrl}/api/v2/process-excel`;
    setLoading(true);
    const formData = new FormData();

    const toastFracction = toast("Procesando...", {
      icon: "⏳ ",
      style: {
        borderRadius: "10px",
        background: "#fff",
        color: "#000",
      },
    });

    if (file) {
      formData.append("file", file);
      formData.append("countryCode", countryClasiffication);
      // console.log(file, "condicional");
    } else {
      setLoading(false);
      alert(t("alert_empty"));
      toast.error(t("alert_empty"), {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }

    const options = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    // console.warn("Options to send: ", options);
    // console.log(file.name); // Nombre del archivo
    // console.log(file.size); // Tamaño del archivo en bytes
    // console.log(formData.get("data"), "conty"); // Esto debería mostrar el contenido de 'data'

    try {
      const response = await fetch(apiUrl, options);
      const res = await response.blob();
      toast.dismiss(toastFracction);
      // console.log(res);

      if (response.ok || response.status === 200) {
        toast.success(res.message || res.error || "Proceso completado", {
          id: toastFracction,
          duration: 5000,
        });

        onSuccess(res);
        setBlockBtn(false);
        setLoading(false);
      } else {
        toast.dismiss(toastFracction);

        toast.error(res.error, {
          icon: "❗❗ ",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        setLoading(false);
      }
      // console.log(response.data);
      convertToXLSX(response.data); // Use this function to handle file download

      setFile(null);
    } catch (error) {
      console.error(error);

      setLoading(false);
      toast.dismiss(toast.error);

      const errorResponse = error.response?.data?.error || t("alert_error");
      // console.log(errorResponse, "variable");

      toast.error(errorResponse, {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const convertToXLSX = (data) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const downloadLink = window.URL.createObjectURL(blob);
    setFile(downloadLink); // Set the link for downloading
  };

  const handleExitBtn = () => {
    setFile(null);
  };

  const handleToast = () => {
    // Toast para avisar que ha va a clasificar en X pais
    const toastCountry = toast(
      `Vas a clasificar bajo las normas ${
        countryClasiffication === "COL" ? "colombianas" : "mexicanas"
      }`,
      {
        // icon: "😉 ",
        style: {
          borderRadius: "10px",
          background: `${
            countryClasiffication === "COL"
              ? "linear-gradient(to bottom, #fbe964 33.33%, #fbe964 33.33%, #648bfb 66.66%, #648bfb 66.66%, #fb6464 100%)"
              : "linear-gradient(to left, #e95252 0%, #ffffff 40%, #ffffff 50%, #70e952 100%)"
          }`,
          textShadow: "2px 2px 4px rgba(0.6, 0.6, 0.6, 0.6)",
          color: "#fff",
          textAlign: "center",
        },
      }
    );
  };
  const handleToastAndFraction = () => {
    handleToast();

    setTimeout(() => {
      handlePostToApi();
    }, 2000);
  };

  useEffect(() => {
    // console.log(file);
  }, [file]);

  return (
    <>
      <div className="description-instructions mt-5">
        <div className="upload-title">
          {/* <h1>{t("xlsx_title")}</h1> */}
          {/* <p>También puedes subir un archivo en formato PDF, como una factura comercial o una ficha técnica, que contenga descripciones y/o imágenes de los productos que deseas clasificar.</p> */}
        </div>

        <div className="upload-button">
          <h2>1. {t("xlsx_instruction_1")}</h2>
          <p>{t("xlsx_instruction_1_p")}</p>

          <input
            type="file"
            id="pdfUpload"
            accept=".xlsx"
            onChange={handleFileChange}
            style={{ display: "none" }}
            disabled={setLoading}
          />
          <label htmlFor="pdfUpload" className="btn">
            <i className="bi bi-file-earmark-arrow-up font-gray-strong"></i>
            {t("pdf_placeholder")}
          </label>

          <div className={file ? "product-uploaded" : ""}>
            <div>
              {file && (
                <i className="bi bi-check-circle-fill text-success me-3"></i>
              )}
              {file && file?.name}
            </div>
            <span onClick={handleExitBtn}>
              {file && <i className="bi bi-x-lg font-gray-strong"></i>}
            </span>
          </div>
        </div>

        <span className="my-2 btn btn-camtom-xlsx">
          <a
            className="text-decoration-none"
            href="/template_tariffPro_xlsx.xlsx"
            download={true}
          >
            Descargar template
          </a>
        </span>

        <div className="button-section">
          <button
            className="btn-classify"
            onClick={handleToastAndFraction}
            disabled={blockBtn}
          >
            {t("btn_get_ranking")}
          </button>
        </div>
      </div>
    </>
  );
}

export default TariffXlsx;
