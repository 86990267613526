import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEs from "./i18n/translationEs.json";
import translationEn from "./i18n/translationEn.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEn,
    },
    es: {
      translation: translationEs,
    },
  },
  lng: "es", // Por defecto
  fallbackLng: "en", // si no se haya la traduccion
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
