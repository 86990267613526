import { useEffect, useState } from "react";
import getServices from "../../../services/getAgentServices";

const TariffNoms = ({ tariff }) => {
  const [tariffResponse, setTariffResponse] = useState(null);
  // console.log(tariff, "tariff parametro que recibe el child");
  useEffect(() => {
    const fetchNomsAndNico = async () => {
      const endpoint = "/api/v3/tarifa/consulta";
      const service = new getServices();

      try {
        await service.fetchAllWithUrlTariff(endpoint, tariff);

        const response = service.getResponse();
        setTariffResponse(response);
        // console.log(response);
      } catch (error) {
        console.error("Error fetching NOMs and NICO:", error);
      }
    };
    fetchNomsAndNico();
  }, [tariff]);
  return (
    <div class="d-flex flex-column">
      <section className="d-flex flex-row gap-4 w-100 mb-2">
        <p className="w-10 mb-0 text-gray">UMT</p>
        <span className="d-flex flex-column w-25">
          <p className="w-100 mb-0 text-gray text-nowrap">Arancel</p>
          <span className="d-flex flex-row w-100 gap-3 ps-2">
            <small className="font-gray-light w-50">IMP.</small>
            <small className="font-gray-light w-50">EXP.</small>
          </span>
        </span>
        <span className="d-flex flex-column w-50">
          <p className="w-100 mb-0 text-gray text">IVA</p>

          <span className="d-flex w-100 gap-2">
            <small className="font-gray-light text w-50">
              Región Fronteriza
            </small>
            <small className="font-gray-light text w-50">
              Resto del Territorio
            </small>
          </span>
        </span>
        <p className="w-25 text-gray text">NOM</p>
        <p className="w-10 text-gray text">NICO</p>
      </section>
      {/* thead <=*/}
      <section className="d-flex flex-row gap-4 w-100 mb-2 align-items-start h-3rem">
        {tariffResponse?.tarifa?.tarifa
          ?.filter((el) => el.UMT !== "-1")
          ?.map((el, index) => (
            <small
              className="w-10 font-gray-strong border p-2 rounded-pill text-center h-25 bg-gray-noms"
              key={index}
            >
              {el.UMT}
            </small>
          ))}

        <div class="d-flex flex-column gap-2 w-25">
          {tariffResponse?.tarifa?.tarifa[0] ? (
            <span className="d-flex flex-row align-items-center gap-2">
              <small className="w-50 font-gray-strong border p-2 rounded-pill text-center bg-gray-noms">
                {tariffResponse?.tarifa?.tarifa[0]?.IGI} %
              </small>
              <small className="w-50 font-gray-strong border p-2 rounded-pill text-center bg-gray-noms">
                {tariffResponse?.tarifa?.tarifa[0]?.IGE} %
              </small>
            </span>
          ) : null}
        </div>
        <span className="d-flex flex-row w-50 gap-2">
          <div className="d-flex w-50">
            <small className="font-gray-strong border p-2 rounded-pill text-center bg-gray-noms ">
              {tariffResponse?.iva?.iva[0].iva_exportacion} %
            </small>
          </div>
          <div className="d-flex w-50">
            <small className="font-gray-strong border p-2 rounded-pill text-center bg-gray-noms ">
              {tariffResponse?.iva?.iva[0].iva_importacion} %
            </small>
          </div>
          {/* <div className="d-flex">
            <small className="p-2 rounded-pill text-center bg-warning">
              En mantenimiento
            </small>
          </div> */}
        </span>
        <span class="d-flex flex-row w-25 flex-wrap gap-2">
          {tariffResponse?.noms?.noms[0]?.NOM ? (
            <small className="w-100 mb-0 text-gray text border rounded-5 p-2 text-center bg-gray-noms">
              {tariffResponse?.noms?.noms[0]?.NOM}
            </small>
          ) : null}
        </span>

        <span class="d-flex flex-row flex-wrap w-10 gap-2">
          {tariffResponse?.tarifa?.tarifa?.length === 0
            ? null
            : tariffResponse?.tarifa?.tarifa
                ?.filter((el) => el.NICO !== "-1")
                .map((el, index) => (
                  <small
                    className="mb-0 text-gray text border rounded-5 p-2 text-center bg-gray-noms"
                    key={index}
                  >
                    {el.NICO}
                  </small>
                ))}
        </span>
      </section>
      {/* Sección noms <=*/}
    </div>
  );
};
export default TariffNoms;
