import { createSlice } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import Cookies from "js-cookie";

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: Cookies.get("tokenUser") ? Cookies.get("tokenUser") : null,
    tradingCompanyId: Cookies.get("tradingCompanyId") || null,
    tradingCompanyEmployeeId: Cookies.get("tradingCompanyEmployeeId") || null,
  },
  reducers: {
    loginUser: (state, action) => {
      state.token = action.payload.token;
      state.tradingCompanyId = action.payload.tradingCompanyId;
      state.tradingCompanyEmployeeId = action.payload.tradingCompanyEmployeeId;
      Cookies.set("tokenUser", action.payload.token, { expires: 3 });
      Cookies.set(
        "tradingCompanyEmployeeId",
        action.payload.tradingCompanyEmployeeId,
        {
          expires: 3,
        }
      );
      Cookies.set("tradingCompanyId", action.payload.tradingCompanyId, {
        expires: 3,
      });
    },
    logOutUser: (state) => {
      state.token = null;
      state.tradingCompanyId = null;
      state.tradingCompanyEmployeeId = null;
      Cookies.remove("tokenUser");
      Cookies.remove("tradingCompanyId");
      Cookies.remove("tradingCompanyEmployeeId");
    },
  },
});

const agentSlice = createSlice({
  name: "agent",
  initialState: {
    token: Cookies.get("tokenAgent") ? Cookies.get("tokenAgent") : null,
    email: Cookies.get("email") || null,
    customsAgencyId: Cookies.get("customsAgencyId") || null,
    customsAgencyEmployeeId: Cookies.get("customsAgencyEmployeeId") || null,
  },
  reducers: {
    loginAgent: (state, action) => {
      state.token = action.payload.token;
      state.email = action.payload.email;
      state.customsAgencyId = action.payload.customsAgencyId;
      state.customsAgencyEmployeeId = action.payload.customsAgencyEmployeeId;
      Cookies.set("tokenAgent", action.payload.token, { expires: 3 });
      Cookies.set("email", action.payload.email, { expires: 3 });
      Cookies.set("customsAgencyId", action.payload.customsAgencyId, {
        expires: 3,
      });
      Cookies.set(
        "customsAgencyEmployeeId",
        action.payload.customsAgencyEmployeeId,
        { expires: 3 }
      );
    },
    logOutAgent: (state) => {
      state.token = null;
      state.email = null;
      state.customsAgencyId = null;
      state.customsAgencyEmployeeId = null;
      Cookies.remove("tokenAgent");
      Cookies.remove("email");
      Cookies.remove("customsAgencyId");
      Cookies.remove("customsAgencyEmployeeId");
    },
  },
});

export const { loginUser, logOutUser } = userSlice.actions;
export const { loginAgent, logOutAgent } = agentSlice.actions;

const rootReducer = combineReducers({
  user: userSlice.reducer,
  agent: agentSlice.reducer,
});

export default rootReducer;
