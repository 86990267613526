import { Routes, Route, Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "react-router-dom";

import "react-calendar/dist/Calendar.css";

import Sidebar from "./Sidebar";
import AgentUsers from "./AgentUsers";

import TariffProImg from "./tariff/TariffProImg";
import TariffProPdf from "./tariff/TariffProPdf";
import TariffProText from "./tariff/TariffProText";
import TariffProXlsx from "./tariff/TariffProXlsx";
import Products from "../Agents/products/Products";
import AddQuoterView from "./quoter/AddQuoterView";
import Digiter from "../digiter/Digiter.js";
import Help from "./Help";

import "./DashboardAgent.css";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Companies from "./companies/CompaniesView";
import ListQuoterView from "./quoter/ListQuoterView";
import TariffPro from "./tariff/TariffPro";
import Billing from "../billing/Billing";
import { store } from "../../../redux/store";
import History from "./HistoryView.js";

import { logOutAgent } from "../../../redux/reducers.js";
import toast from "react-hot-toast";

function AgentDashboard() {
  const token = useSelector((state) => state.agent.token);
  // console.log(tokenAgent, "FUNCION HANDLEISTOKEN");
  const dispatch = useDispatch();
  const location = useLocation();
  const [countryClasiffication, setCountryClasiffication] = useState();

  const PrivateRouteAgent = ({ children }) => {
    return token ? children : <Navigate to="/login/customs-agency" replace />;
  };

  useEffect(() => {
    const checkTokenAgent = Cookies.get("tokenAgent");
    const decodedTokenAgent = jwtDecode(checkTokenAgent);
    const currentTime = Date.now() / 1000;

    if (decodedTokenAgent.exp < currentTime) {
      // console.log("logging out user...");
      dispatch(logOutAgent());
    }
  }, [dispatch, location]);

  // Consultar País desde el hijo
  const handleCountry = (result) => {
    setCountryClasiffication(result); // Almacena la respuesta en el estado
    toast.dismiss();
    // Toast para avisar que ha cambiado de pais
    const toastCountry = toast(
      `Estas en Camtom ${result === "COL" ? "Colombia" : "México"}`,
      {
        // icon: "😉 ",
        style: {
          borderRadius: "10px",
          background: `${
            result === "COL"
              ? "linear-gradient(to bottom, #fbe964 33.33%, #fbe964 33.33%, #648bfb 66.66%, #648bfb 66.66%, #fb6464 100%)"
              : "linear-gradient(to left, #e95252 0%, #ffffff 40%, #ffffff 50%, #70e952 100%)"
          }`,
          textShadow: "2px 2px 4px rgba(0, 0.6, 0, 0.6)",
          color: "#fff",
        },
        duration: 2500,
      }
    );
  };
  return (
    <div className="agent-dashboard">
      <Sidebar country={handleCountry} />
      <Routes>
        <Route
          path="/"
          element={<AgentDashboardPrev country={countryClasiffication} />}
        />
        <Route path="/users" element={<AgentUsers />} />
        <Route path="/tariff-pro-companies" element={<Companies />} />
        <Route path="/new-simulations" element={<AddQuoterView />} />
        <Route path="/my-simulations" element={<ListQuoterView />} />
        <Route path="/digiter" element={<Digiter />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/history" element={<History />} />
        <Route
          path="/tariff-pro-clasifications-database"
          element={<Products />}
        />
        <Route
          path="/tariff-pro"
          element={
            <PrivateRouteAgent>
              <TariffPro />
            </PrivateRouteAgent>
          }
        >
          {/* SUBRUTAS DE TARIFF */}
          <Route
            path="tariff-pro-img"
            element={
              <TariffProImg countryClasiffication={countryClasiffication} />
            }
          />
          <Route
            path="tariff-pro-pdf"
            element={
              <TariffProPdf countryClasiffication={countryClasiffication} />
            }
          />
          <Route
            path="tariff-pro-text"
            element={
              <TariffProText countryClasiffication={countryClasiffication} />
            }
          />
          <Route
            path="tariff-pro-xlsx"
            element={
              <TariffProXlsx countryClasiffication={countryClasiffication} />
            }
          />
        </Route>
        <Route path="/help" element={<Help />} />
      </Routes>
    </div>
  );
}

function AgentDashboardPrev({ country }) {
  const { t } = useTranslation(); // i18n // traduccion
  const state = store.getState(); // Estado de redux
  const mail = state.user.email;
  const email = Cookies.get("email");
  // console.log(country, "dash");

  return (
    <div className="DashboardAgent">
      <div className="main-content">
        <h1>
          ¡{t("home_welcome")} {email}!
        </h1>

        <div className="card-container">
          <Link to={"/agent-dashboard/tariff-pro/tariff-pro-img"}>
            <div className="card">
              <i className="bi bi-file-image me-3 fs-1 text-dark"></i>
              <p>{t("home_img")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
          <Link to="/agent-dashboard/tariff-pro/tariff-pro-pdf">
            <div className="card">
              <i className="bi bi-filetype-pdf me-3 fs-1 text-dark"></i>
              <p>{t("home_pdf")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
          <Link to="/agent-dashboard/tariff-pro/tariff-pro-text">
            <div className="card">
              <i className="bi bi-justify-left me-3 fs-1 text-dark"></i>
              <p>{t("home_txt")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
          <Link to="/agent-dashboard/tariff-pro/tariff-pro-xlsx">
            <div className="card">
              <i className="bi bi-filetype-xlsx me-3 fs-1 text-dark"></i>
              <p>{t("home_xlsx")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
        </div>

        <div className="p-5 d-flex align-items-center justify-content-center">
          <video
            src={
              country === "COL"
                ? "https://firebasestorage.googleapis.com/v0/b/camtom-b6444.appspot.com/o/videos%2Fcolombia_final.mp4?alt=media&token=c4c6d8df-3b11-44b3-b10f-13da16e21642"
                : "https://firebasestorage.googleapis.com/v0/b/camtom-b6444.appspot.com/o/videos%2FMexico_final.mp4?alt=media&token=37c36af7-6bff-4754-b5b2-1067e4049325"
            }
            className={`h-auto align-self-end rounded ${
              country ? "w-40" : "w-30 "
            }`}
            autoPlay="true"
            muted="true"
          ></video>
        </div>
      </div>
    </div>
  );
}

export default AgentDashboard;
