import { useNavigate } from "react-router-dom";
import heroImage from "../imagesCommon/transport-logistics-concept.jpg";
import camtomLogo from "../imagesCommon/logoCamtomDark.svg";

export default function Redirect() {
  const navigate = useNavigate();

  const handleRedirectLoginTradingCompany = () => {
    navigate("/user-dashboard");
  };

  const handleRedirectLoginCustomsAgency = () => {
    navigate("/agent-dashboard");
  };
  return (
    <div className="d-flex flex-column flex-md-row justify-content-center h-100 w-100 aparecer">
      <div className="text-center col-sm w-sm-100 responsive-div">
        <img
          src={heroImage}
          alt="Camtom, IA para agencias"
          className="translateLeft object-cover w-100 h-100"
        />
      </div>
      <div className="d-flex flex-column align-items-center col w-sm-100 p-5">
        <div className="d-flex flex-column align-items-center justify-content-md-center w-100 h-100 gap-3 text-center position-relative">
          <img src={camtomLogo} className="w-75" alt="Camtom" />
          <p>Servicios aduanales México y Colombia</p>

          <span>
            <button
              type="button"
              onClick={handleRedirectLoginTradingCompany}
              className="btn-camtom-light mb-3"
            >
              Portal de Empresa
            </button>

            <br />
            <button
              type="button"
              onClick={handleRedirectLoginCustomsAgency}
              className="btn-camtom"
            >
              Portal de Agencia Aduanal
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}
