import { Routes, Route, Navigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//dependencias mapa
import "react-calendar/dist/Calendar.css";
import Mapbox from "react-map-gl";
import axios from "axios";

//dependencias calendario
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie"; // Assuming you are using js-cookie
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import Sidebar from "./Sidebar";
import "./DashboardMVP.css";

import TariffProImg from "./TariffPro/TariffProImg";
import TariffProPdf from "./TariffPro/TariffProPdf";
import TariffProText from "./TariffPro/TariffProText";

import TariffProXlsx from "./TariffPro/TariffProXlsx";
import Products from "./Products/Products";
import ListQuoterView from "./quoter/ListQuoterView";
import AddQuoterView from "./quoter/AddQuoterView";
import Digiter from "../digiter/Digiter";
import TariffPro from "./TariffPro/TariffPro";
import { useTranslation } from "react-i18next";
import BillingCompany from "../billing/BillingCompany";
import HistoryView from "./HistoryView";

import { logOutUser } from "../../../redux/reducers.js";
import { store } from "../../../redux/store.js";
import toast from "react-hot-toast";

function UserDashboard() {
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const location = useLocation();
  const [countryClasiffication, setCountryClasiffication] = useState();
  const state = store.getState();

  const ID = state.user.tradingCompanyEmployeeId;
  console.log(ID, "id desde dash");
  const PrivateRouteAgent = ({ children }) => {
    return token ? children : <Navigate to="/login/trading-company" replace />;
  };

  useEffect(() => {
    const checkTokenUser = Cookies.get("tokenUser");
    const decodedTokenUser = jwtDecode(checkTokenUser);
    const currentTime = Date.now() / 1000; // Current time in seconds

    if (decodedTokenUser.exp < currentTime) {
      console.log("logging out user...");
      dispatch(logOutUser());
    }
  }, [dispatch, location]);

  // Consultar País desde el hijo
  const handleCountry = (result) => {
    setCountryClasiffication(result); // Almacena la respuesta en el estado
    toast.dismiss();

    // Toast para avisar que ha cambiado de pais
    const toastCountry = toast(
      `Estas en Camtom ${result === "COL" ? "Colombia" : "México"}`,
      {
        // icon: "😉 ",
        style: {
          borderRadius: "10px",
          background: `${
            result === "COL"
              ? "linear-gradient(to bottom, #fbe964 33.33%, #fbe964 33.33%, #648bfb 66.66%, #648bfb 66.66%, #fb6464 100%)"
              : "linear-gradient(to left, #e95252 0%, #ffffff 40%, #ffffff 50%, #70e952 100%)"
          }`,
          textShadow: "2px 2px 4px rgba(0, 0.6, 0, 0.6)",
          color: "#fff",
        },
        duration: 2500,
      }
    );
  };
  return (
    <div className="UserDashboard">
      <Sidebar country={handleCountry} />
      <Routes>
        <Route
          path="/"
          element={<DashboardPrev country={countryClasiffication} />}
        />
        <Route path="/new-simulations" element={<AddQuoterView />} />
        <Route path="/my-simulations" element={<ListQuoterView />} />
        <Route path="/digiter" element={<Digiter />} />
        <Route path="/billing" element={<BillingCompany />} />
        <Route path="/history" element={<HistoryView />} />
        <Route
          path="/tariff-pro-clasifications-database"
          element={<Products />}
        />
        <Route
          path="/tariff-pro"
          element={
            <PrivateRouteAgent>
              <TariffPro />
            </PrivateRouteAgent>
          }
        >
          {/* SUBRUTAS DE TARIFF */}
          <Route
            path="tariff-pro-img"
            element={
              <TariffProImg
                countryClasiffication={countryClasiffication}
                ID={ID}
              />
            }
          />
          <Route
            path="tariff-pro-pdf"
            element={
              <TariffProPdf
                countryClasiffication={countryClasiffication}
                ID={ID}
              />
            }
          />
          <Route
            path="tariff-pro-text"
            element={
              <TariffProText
                countryClasiffication={countryClasiffication}
                ID={ID}
              />
            }
          />
          <Route
            path="tariff-pro-xlsx"
            element={
              <TariffProXlsx
                countryClasiffication={countryClasiffication}
                ID={ID}
              />
            }
          />
        </Route>
        {/* <Route path="/quotation/" element={<QuoteFormC/>} />
        <Route path="/operations" element={<OperationList/>} />
        <Route path="/operation/documents" element={<DocumentsPerOperationC/>} />
        <Route path="/reports/:userId" element={<ListReports/>} />
        <Route path="/report/:reportId/:userId" element={<Report/>} />
        <Route path="/shipments" element={<Shipments/>} />
        <Route path="/help" element={<Help/>} />
        <Route path="/terms-conditions" element={<TermsConditions/>} />
        <Route path="/success-quote" element={<SuccessQuote/>} />
        <Route path="/editquote/:reportId" element={<EditQuote/>} />
        <Route path="/agent-manager" element={<AgentManager/>} />
        <Route path="/add-product" element={<AddProductForm/>} />
        <Route path="/product-manager" element={<ProductManager/>} />
        <Route path="/edit-product/:productId" element={<EditProduct/>} />
        <Route path="/info-product" element={<InfoProduct/>} />
        <Route path="/new-operation" element={<NewOperationC/>} />
        <Route path="/edit-operation/:operationId" element={<EditOperationUserC/>} />
        <Route path="/info-operation/:operationId" element={<InfoOperationUserC/>} />
        <Route path="/operation/:operationId/documents" element={<DocumentsPerOperation/>} />  */}
      </Routes>
    </div>
  );
}

function DashboardPrev({ country }) {
  const { t } = useTranslation(); // i18n // traduccion
  const email = Cookies.get("email");

  const userId = useSelector((state) => state.user.userId);
  // const userEmail = useSelector((state) => state.user.email);
  const [reports, setReports] = useState([]);

  useEffect(() => {}, []);

  return (
    <div className="DashboardAgent">
      <div className="main-content">
        <h1>
          ¡{t("home_welcome")} {email}!
        </h1>

        <div className="card-container">
          <Link to={"/user-dashboard/tariff-pro/tariff-pro-img"}>
            <div className="card">
              <i className="bi bi-file-image me-3 fs-1 text-dark"></i>
              <p>{t("home_img")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
          <Link to="/user-dashboard/tariff-pro/tariff-pro-pdf">
            <div className="card">
              <i className="bi bi-filetype-pdf me-3 fs-1 text-dark"></i>
              <p>{t("home_pdf")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
          <Link to="/user-dashboard/tariff-pro/tariff-pro-text">
            <div className="card">
              <i className="bi bi-justify-left me-3 fs-1 text-dark"></i>
              <p>{t("home_txt")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
          <Link to="/user-dashboard/tariff-pro/tariff-pro-xlsx">
            <div className="card">
              <i className="bi bi-filetype-xlsx me-3 fs-1 text-dark"></i>
              <p>{t("home_xlsx")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
          {/* <Link to="/user-dashboard/tariff-pro/tariff-pro-url">
            <div className="card">
              <i className="bi bi-link-45deg me-3 fs-1 text-dark"></i>
              <p>{t("home_url")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link> */}

          <div className="p-5 d-flex align-items-center justify-content-center">
            <video
              src={
                country === "COL"
                  ? "https://firebasestorage.googleapis.com/v0/b/camtom-b6444.appspot.com/o/videos%2Fcolombia_final.mp4?alt=media&token=c4c6d8df-3b11-44b3-b10f-13da16e21642"
                  : "https://firebasestorage.googleapis.com/v0/b/camtom-b6444.appspot.com/o/videos%2FMexico_final.mp4?alt=media&token=37c36af7-6bff-4754-b5b2-1067e4049325"
              }
              className={`h-auto align-self-end rounded ${
                country ? "w-40" : "w-30 "
              }`}
              autoPlay="true"
              muted="true"
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
